import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from "react";

import styled from "@emotion/styled";
import {
  Autocomplete,
  autocompleteClasses,
  ListSubheader,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { VariableSizeList } from "react-window";

const LISTBOX_PADDING = 8;

const renderRow = (props) => {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (Object.prototype.hasOwnProperty.call(dataSet, "group")) {
    return (
      <ListSubheader component="div" key={dataSet.key} style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  // TODO: Оставить только {dataSet[1]} и разобраться с getOptionLabel
  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]?.name}
    </Typography>
  );
};

const OuterElementContext = createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

// Adapter for react-window
const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (Object.prototype.hasOwnProperty.call(child, "group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          height={getHeight() + 2 * LISTBOX_PADDING}
          innerElementType="ul"
          itemCount={itemCount}
          itemData={itemData}
          itemSize={(index) => getChildSize(itemData[index])}
          outerElementType={OuterElementType}
          overscanCount={5}
          ref={gridRef}
          width="100%"
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const VirtualizedAutocomplete = ({
  name,
  label,
  options,
  required,
  loading,
  rules,
  InputLabelProps,
  inputProps,
  sx,
  value: customValue,
  getOptionLabel = (option) => option,
  isOptionEqualToValue = (option, value) => option === value,
  ...props
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  if (required) {
    rules = { required: t("main.another.requiredField"), ...rules };
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => {
        return (
          <Autocomplete
            {...field}
            ListboxComponent={ListboxComponent}
            PopperComponent={StyledPopper}
            disableListWrap
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            noOptionsText="Нет доступных вариантов"
            onChange={(e, value) => onChange(value)}
            options={options || []}
            sx={{
              mb: 4,
              ...sx,
            }}
            value={customValue ? customValue : value || null}
            {...props}
            renderInput={(params) => (
              <TextField
                {...params}
                FormHelperTextProps={{
                  sx: {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  ...InputLabelProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                error={!!error}
                fullWidth
                helperText={error && (error?.message || "Unknown error!")}
                label={`${label}${required || !!rules?.required ? " *" : ""}`}
                size="small"
                variant="outlined"
                {...inputProps}
              />
            )}
            renderOption={(props, option, state) => [
              props,
              option,
              state.index,
            ]}
          />
        );
      }}
      rules={rules}
    />
  );
};

export default VirtualizedAutocomplete;
