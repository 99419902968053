import React, { useEffect } from "react";

import { Avatar, Box, Drawer, Grid, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AccountCompany from "./AccountCompany";
import NavSection from "../../components/nav-section/NavSection";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import { NAV_WIDTH, NAV_WIDTH_CLOSE } from "../../constants";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { StyledAccount, StyledBox } from "../../theme/standarts_styles";
import SearchFieldInAppBar from "../header/SearchFieldInAppBar";

export default function Nav() {
  const { t } = useTranslation();

  const isDesktop = useResponsive("up", "lg");

  const { openNav } = useSelector((state) => state.serviceReducer);
  const { toggleOpenNav, setOpenNav } = useActions();

  useEffect(() => {
    // if (!isDesktop && openNav) {
    //   toggleOpenNav()
    // }
    if (!isDesktop) {
      setOpenNav(false);
    }
  }, [isDesktop, setOpenNav]);

  const renderContent = (
    <Grid
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <StyledBox>
          <AccountCompany />
        </StyledBox>
        {!isDesktop && (
          <Box sx={{ px: 1, pt: 1, pb: 0 }}>
            <SearchFieldInAppBar width="100%" />
          </Box>
        )}
        <NavSection />
      </Box>
    </Grid>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        // width: { lg: openNav && (isDesktop ? NAV_WIDTH : 0) }, // Меняем значение width в зависимости от режима
        width: { lg: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE },
      }}
    >
      <Drawer
        PaperProps={{
          sx: {
            width: openNav ? NAV_WIDTH : NAV_WIDTH_CLOSE,
            overflow: "hidden",
            backgroundColor: "layout.main",
            // borderRightStyle: isDesktop ? 'dashed' : 'none',
            visibility: !isDesktop && !openNav ? "hidden" : "visible",
          },
        }}
        onClose={toggleOpenNav}
        open={openNav}
        variant={isDesktop ? "permanent" : "temporary"}
      >
        {renderContent}
      </Drawer>
      {/*{(isDesktop || openNav) &&*/}
      {/*  <Drawer*/}
      {/*    open={openNav}*/}
      {/*    onClose={toggleOpenNav}*/}
      {/*    variant={(isDesktop) ? 'permanent' : 'temporary'}*/}
      {/*    ModalProps={{*/}
      {/*      keepMounted: !isDesktop,*/}
      {/*    }}*/}
      {/*    PaperProps={{*/}
      {/*      sx: {*/}
      {/*        width: NAV_WIDTH,*/}
      {/*        backgroundColor: 'default.paper',*/}
      {/*        borderRightStyle: !isDesktop ? 'none' : 'dashed',*/}
      {/*        visibility: !openNav ? 'hidden' : 'visible',*/}
      {/*      },*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    {renderContent}*/}
      {/*  </Drawer>*/}
      {/*}*/}
    </Box>
  );
}
