import { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, IconButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import CustomTextField from "../../components/TextFields/CustomTextField";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";

const ClientsForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateFullSelectedClient } = useActions();
  const isMobile = useResponsive("down", "md");

  const { selectedClient } = useSelector((state) => state.clientsReducer);

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    setError,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    // console.log(selectedClient.source_id)
    let new_arr = {
      address: data.address || null,
      code: data.code || null,
      country: data.country || null,
      details: data.details || null,
      email: data.email || null,
      full_name: data.full_name || null,
      name: data.name || null,
      web_site: data.web_site || null,
    };
    try {
      await $authHost.put(
        `/clients/${selectedClient.source_id}/update/`,
        new_arr,
      );
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/clients");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleReset = useCallback(() => {
    setValue("name", selectedClient.name);
    setValue("full_name", selectedClient.full_name);
    setValue("code", selectedClient.code);
    setValue("address", selectedClient.address);
    setValue("web_site", selectedClient.web_site);
    setValue("details", selectedClient.details);
    setValue("country", selectedClient.country);
    setValue("email", selectedClient.email);
  }, [
    selectedClient.address,
    selectedClient.code,
    selectedClient.country,
    selectedClient.details,
    selectedClient.email,
    selectedClient.full_name,
    selectedClient.name,
    selectedClient.web_site,
    setValue,
  ]);

  const handleBack = () => {
    navigate("/clients");
  };

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  const validateName = async (value) => {
    if (value === selectedClient.name) {
      return true;
    }

    if (value) {
      const trimmedName = value.toString().trim();
      if (trimmedName) {
        const { data } = await $authHost.get(
          `/clients/unique_check/?name=${trimmedName}`,
        );
        if (data?.unique_name === false) {
          return "Наименование должно быть уникальным";
        }
        if (data?.unique_name === true) {
          return true;
        }
      }
    }
  };

  const validateCode = async (value) => {
    if (value === selectedClient.code) {
      return true;
    }

    if (value) {
      const trimmedCode = value.toString().trim();
      if (trimmedCode) {
        const { data } = await $authHost.get(
          `/clients/unique_check/?code=${trimmedCode}`,
        );
        if (data?.unique_code === false) {
          return "Код должен быть уникальным";
        }
        if (data?.unique_code === true) {
          return true;
        }
      }
    }
  };

  return (
    <Box sx={{ flexDirection: "column", px: 0, py: isMobile ? 2 : 4 }}>
      {loading && <LoadingSpinner />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isMobile && (
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              sx={{ mb: 3 }}
            >
              <Grid item>
                <Typography color="text.secondary" variant="subtitle1">
                  Редактировать
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleBack} sx={{ p: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <CustomTextField
            label="Наименование"
            name="name"
            registerOptions={{
              required: t("main.another.requiredField"),
              validate: (value) => validateName(value),
            }}
            size="small"
            sx={{ width: "100%", fontSize: "12px", mb: 2 }}
          />
          <CustomTextField
            label="Полное наименование"
            name="full_name"
            registerOptions={{
              required: t("main.another.requiredField"),
            }}
            size="small"
            sx={{ width: "100%", fontSize: "12px", mb: 2 }}
          />
          <CustomTextField
            label="Код клиента"
            name="code"
            registerOptions={{
              required: t("main.another.requiredField"),
              validate: (value) => validateCode(value),
            }}
            size="small"
            sx={{ width: "100%", fontSize: "12px", mb: 2 }}
          />
          <CustomTextField
            label="Адрес"
            multiline={true}
            name="address"
            registerOptions={
              {
                // required: t('main.another.requiredField'),
              }
            }
            rows={3}
            size="small"
            sx={{ width: "100%", fontSize: "12px", mb: 2 }}
          />
          <CustomTextField
            label="Веб-сайт"
            name="web_site"
            registerOptions={
              {
                // required: t('main.another.requiredField'),
              }
            }
            size="small"
            sx={{ width: "100%", fontSize: "12px", mb: 2 }}
          />
          <CustomTextField
            label="Email"
            name="email"
            registerOptions={{
              // required: t('main.another.requiredField'),
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: t("main.another.wrongInput"),
              },
            }}
            size="small"
            sx={{ width: "100%", fontSize: "12px", mb: 2 }}
          />
          <CustomTextField
            label="Реквизиты"
            multiline={true}
            name="details"
            registerOptions={
              {
                // required: t('main.another.requiredField'),
              }
            }
            rows={3}
            size="small"
            sx={{ width: "100%", fontSize: "12px", mb: 2 }}
          />
          <CustomTextField
            label="Страна"
            name="country"
            registerOptions={
              {
                // required: t('main.another.requiredField'),
              }
            }
            size="small"
            sx={{ width: "100%", fontSize: "12px", mb: 2 }}
          />
          {isMobile ? (
            <Stack
              alignItems="center"
              direction="column"
              justifyContent="center"
              spacing={2}
              sx={{ width: "100%", mt: 3 }}
            >
              <Button sx={{ width: "100%" }} type="submit" variant="contained">
                Сохранить
              </Button>
              <Button
                disabled={!isDirty}
                onClick={handleReset}
                sx={{ width: "100%" }}
                variant="outlined"
              >
                Сбросить
              </Button>
            </Stack>
          ) : (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item>
                <Button type="submit" variant="contained">
                  Сохранить
                </Button>
                <Button
                  color="primary"
                  onClick={handleBack}
                  sx={{ ml: 1 }}
                  variant="text"
                >
                  Назад
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={handleReset}
                  sx={{ ml: 1 }}
                  variant="text"
                >
                  Сбросить
                </Button>
              </Grid>
            </Stack>
          )}
        </form>
      </FormProvider>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Box>
  );
};

export default ClientsForm;
