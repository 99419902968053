import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const TableStatesSlice = createSlice({
  name: "tableStates",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setTableState(state, { payload }) {
      state[payload.id] = payload.tableState;
    },
  },
});

export const { actions: tableStatesActions, reducer: tableStatesReducer } =
  TableStatesSlice;
export default TableStatesSlice.reducer;
