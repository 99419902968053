import React from "react";

import TextField from "@mui/material/TextField";
import { useFormContext } from "react-hook-form";

const CustomTextField = ({
  label,
  name,
  required,
  registerOptions,
  secondErrorText,
  multiline,
  rows,
  ...rest
}) => {
  const req = required || !!registerOptions?.required;

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div style={{ position: "relative", marginBottom: "12px", width: "100%" }}>
      <TextField
        {...register(name, registerOptions)}
        {...rest}
        InputLabelProps={{
          shrink: true,
        }}
        error={!!errors[name]} // Установите error на основе наличия ошибки из react-hook-form
        label={`${label}${req ? " *" : ""}`}
        multiline={multiline}
        rows={rows}
        variant="outlined"
      />
      <span
        style={{
          position: "absolute",
          bottom: "4px",
          fontSize: "12px",
          color: "red",
          left: 14, // Выравнивание по левому краю
        }}
      >
        {errors[name] && (errors[name]?.message || "Unknown error!")}
      </span>
    </div>
  );
};

export default CustomTextField;
