import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

const PRIMARY = {
  lighter: "#F2E7FE",
  light: "#DBB2FF",
  main: "#BB86FC",
  dark: "#985EFF",
  darker: "#7F39FB",
  contrastText: "#000000",
};

const SECONDARY = {
  lighter: "#C8FFF4",
  light: "#70EFDE",
  main: "#03DAC5",
  dark: "#00C4B4",
  darker: "#00B3A6",
  contrastText: "#000000",
};

const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#000000",
};

const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: "#000000",
};

const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: "#000000",
};

const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#000000",
};

const darkPalette = {
  common: {
    black: "#000000",
    white: "#fff",
    layout: "#272931",
  },
  layout: {
    main: "#22232A",
    element: "#ffffff",
    default: "#272931",
    accent_element: "#7F39FB",
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: "rgba(0,0,0,0.12)",
  text: {
    primary: "rgba(255,255,255,0.95)",
    secondary: "rgba(255,255,255,0.7)",
    disabled: "rgba(255,255,255,0.5)",
  },
  background: {
    paper: "#22232A",
    default: "#1B1B1F",
    neutral: "#272931",
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default darkPalette;
