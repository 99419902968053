import { useEffect, useState } from "react";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Button, Card, IconButton, Popover } from "@mui/material";
import Stack from "@mui/material/Stack";
import { LocalizationProvider, YearCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import Descriptions from "./Descriptions";
import LoadingIndicator from "../../components/LoadingIndicator";
import MaterialTable from "../../components/MaterialTable";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const TimesheetYear = () => {
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(dayjs());
  const [anchorYear, setAnchorYear] = useState(null);

  const { timesheetsYear } = useSelector((state) => state.timesheetsReducer);

  const { setTimesheetsYear } = useActions();

  const columns = [
    {
      value: "ya",
      label: "Я",
      color: "#bdd7ee",
    },
    {
      value: "ot",
      label: "ОТ + ОВ",
      color: "#a9d090",
    },
    {
      value: "no",
      label: "НО + НВ",
      color: "#d9d9d9",
    },
    {
      value: "rp",
      label: "РП + С",
      color: "#cfbce9",
    },
  ];

  const quarters = [1, 2, 3, 4];

  const loadData = async (year) => {
    setLoading(true);
    try {
      const response = await $authHost.get("/timesheet");
      const data = response.data.items;
      setTimesheetsYear(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // loadData();
  }, [year]);

  const tableColumns = [
    {
      accessorKey: "pp",
      header: "№",
      size: 1,
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user.display_name",
      header: "Сотрудник",
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user.department",
      header: "Отдел",
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    ...quarters.map((q) => ({
      header: `${q} квартал`,
      columns: columns.map((c) => ({
        accessorKey: `calendar.${q}.${c.value}`,
        header: c.label,
        size: 1,
        enableEditing: false,
        muiTableBodyCellProps: ({ cell, table }) => ({
          sx: {
            backgroundColor: `${c.color} !important`,
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
        ),
      })),
    })),
  ];

  const handleClickYear = (event) => {
    setAnchorYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorYear(null);
  };

  const handleNext = () => {
    const nextYear = dayjs(year).add(1, "year");
    setYear(nextYear);
  };

  const handlePrev = () => {
    const nextYear = dayjs(year).subtract(1, "year");
    setYear(nextYear);
  };

  return (
    <Card sx={{ pt: 1, pb: 2, mt: 2 }}>
      <Stack alignItems="center" direction="row" sx={{ p: 2 }}>
        <IconButton onClick={handlePrev}>
          <ArrowCircleLeftIcon fontSize="large" />
        </IconButton>
        <Button
          onClick={handleClickYear}
          sx={{ minWidth: "70px !important" }}
          variant="outlined"
        >
          {dayjs(year).format("YYYY")}
        </Button>
        <IconButton onClick={handleNext}>
          <ArrowCircleRightIcon fontSize="large" />
        </IconButton>
        <Popover
          anchorEl={anchorYear}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleCloseYear}
          open={!!anchorYear}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <YearCalendar onChange={(v) => setYear(v)} value={year} />
          </LocalizationProvider>
        </Popover>
      </Stack>
      <MaterialTable
        columns={tableColumns}
        data={timesheetsYear}
        editDisplayMode="cell"
        enableEditing
        initialState={{
          density: "compact",
        }}
        muiTableBodyCellProps={{
          sx: {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
        muiTableContainerProps={{
          sx: {
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
        muiTableHeadCellProps={{
          sx: { borderRight: "1px solid rgba(224, 224, 224, 1)" },
        }}
        state={{
          showProgressBars: loading,
        }}
      />
      <Descriptions />
      {loading && <LoadingIndicator />}
    </Card>
  );
};

export default TimesheetYear;
