import React from "react";

import { MuiTelInput } from "mui-tel-input";
import { Controller, useFormContext } from "react-hook-form";

const CustomMuiTelInput = ({ name, label, rules, ...rest }) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const error = errors[name];
  const req = !!rules?.required;

  return (
    <div style={{ position: "relative", marginBottom: "12px", width: "100%" }}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <MuiTelInput
            label={`${label}${req ? " *" : ""}`}
            {...field}
            defaultCountry="RU"
            error={error}
            fullWidth
            langOfCountryName="ru"
            sx={
              {
                // width: '90%',
                // mb: 2,
              }
            }
            {...rest}
          />
        )}
        rules={rules}
      />
      <span
        style={{
          position: "absolute",
          bottom: "4px",
          fontSize: "12px",
          color: "red",
          left: 14,
        }}
      >
        {error && (error.message || "Unknown error!")}
      </span>
    </div>
  );
};

export default CustomMuiTelInput;
