import { useEffect, useState } from "react";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Button, ButtonGroup, Card, IconButton, Popover } from "@mui/material";
import Stack from "@mui/material/Stack";
import { LocalizationProvider, YearCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import Descriptions from "./Descriptions";
import LoadingIndicator from "../../components/LoadingIndicator";
import MaterialTable from "../../components/MaterialTable";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { MONTHES } from "../../constants";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const TimesheetQuarter = () => {
  const [loading, setLoading] = useState(false);
  const [quarter, setQuarter] = useState(
    MONTHES.find((month) => month.value === dayjs().month() + 1)?.quarter,
  );
  const [year, setYear] = useState(dayjs());
  const [anchorQuarter, setAnchorQuarter] = useState(null);
  const [anchorYear, setAnchorYear] = useState(null);

  const { timesheetsQuarter } = useSelector((state) => state.timesheetsReducer);

  const { setTimesheetsQuarter } = useActions();

  const quarters = [1, 2, 3, 4];

  const columns = [
    {
      value: "Я",
      label: "Я",
      color: "#bdd7ee",
    },
    {
      value: "ОТ+ОВ",
      label: "ОТ + ОВ",
      color: "#a9d090",
    },
    {
      value: "НО+НВ",
      label: "НО + НВ",
      color: "#d9d9d9",
    },
    {
      value: "РП+С",
      label: "РП + С",
      color: "#cfbce9",
    },
  ];

  const loadData = async () => {
    setLoading(true);
    try {
      const selectedYear = dayjs(year).year();

      const response = await $authHost.get(
        // `/report_cards/all/year/?year=${selectedYear}&size=5`,
        `/report_cards/all/quarter/?quarter=${quarter}&year=${selectedYear}&size=5`,
      );
      const data = response.data.items;
      setTimesheetsQuarter(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // loadData();
  }, [quarter, year]);

  const tableColumns = [
    {
      accessorKey: "pp",
      header: "№",
      size: 1,
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user.display_name",
      header: "Сотрудник",
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user.department",
      header: "Отдел",
      enableEditing: false,
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    ...MONTHES.filter((month) => month.quarter === quarter).map((m) => ({
      header: m.name,
      columns: columns.map((c) => ({
        accessorKey: `calendar.${m.value}.${c.value}`,
        header: c.label,
        size: 1,
        enableEditing: false,
        muiTableBodyCellProps: ({ cell, table }) => ({
          sx: {
            backgroundColor: `${c.color} !important`,
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          },
        }),
        Cell: ({ renderedCellValue, row }) => (
          <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
        ),
      })),
    })),
  ];

  const handleClickQuarter = (event) => {
    setAnchorQuarter(event.currentTarget);
  };

  const handleCloseQuarter = () => {
    setAnchorQuarter(null);
  };

  const handleClickYear = (event) => {
    setAnchorYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorYear(null);
  };

  const handleNext = () => {
    let nextQuarter = null;

    if (quarter < 4) {
      nextQuarter = quarter + 1;
    }

    if (quarter === 4) {
      nextQuarter = 1;
      const nextYear = dayjs(year).add(1, "year");
      setYear(nextYear);
    }

    setQuarter(nextQuarter);
  };

  const handlePrev = () => {
    let nextQuarter = null;

    if (quarter > 1) {
      nextQuarter = quarter - 1;
    }

    if (quarter === 1) {
      nextQuarter = 4;
      const nextYear = dayjs(year).subtract(1, "year");
      setYear(nextYear);
    }

    setQuarter(nextQuarter);
  };

  return (
    <Card sx={{ pt: 1, pb: 2, mt: 2 }}>
      <Stack alignItems="center" direction="row" sx={{ p: 2 }}>
        <IconButton onClick={handlePrev}>
          <ArrowCircleLeftIcon fontSize="large" />
        </IconButton>
        <ButtonGroup variant="outlined">
          <Button
            onClick={handleClickQuarter}
            sx={{ minWidth: "110px !important" }}
          >
            {`${quarter} квартал`}
          </Button>
          <Button
            onClick={handleClickYear}
            sx={{ minWidth: "70px !important" }}
          >
            {dayjs(year).format("YYYY")}
          </Button>
        </ButtonGroup>
        <IconButton onClick={handleNext}>
          <ArrowCircleRightIcon fontSize="large" />
        </IconButton>
        {/*<Popover*/}
        {/*  open={!!anchorQuarter}*/}
        {/*  anchorEl={anchorQuarter}*/}
        {/*  onClose={handleCloseQuarter}*/}
        {/*  anchorOrigin={{*/}
        {/*    vertical: 'bottom',*/}
        {/*    horizontal: 'left',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  123*/}
        {/*</Popover>*/}
        <Popover
          anchorEl={anchorYear}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleCloseYear}
          open={!!anchorYear}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <YearCalendar onChange={(v) => setYear(v)} value={year} />
          </LocalizationProvider>
        </Popover>
      </Stack>
      <MaterialTable
        columns={tableColumns}
        data={timesheetsQuarter}
        editDisplayMode="cell"
        enableEditing
        initialState={{
          density: "compact",
        }}
        muiTableBodyCellProps={{
          sx: {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
        muiTableContainerProps={{
          sx: {
            borderTop: "1px solid rgba(224, 224, 224, 1)",
            borderLeft: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
        muiTableHeadCellProps={{
          sx: { borderRight: "1px solid rgba(224, 224, 224, 1)" },
        }}
        state={{
          showProgressBars: loading,
        }}
      />
      <Descriptions />
      {loading && <LoadingIndicator />}
    </Card>
  );
};

export default TimesheetQuarter;
