import { io } from "socket.io-client";

import { store } from "../store/store";

export const socket = io(window.REACT_APP_BASE_URL, {
  transports: ["websocket", "polling", "flashsocket"],
  auth: (cb) => {
    cb({ token: store.getState().authReducer.token });
  },
  path: `/${process.env.NODE_ENV === "development" ? "dev" : "erp"}/socket.io`,
  autoConnect: false,
});
