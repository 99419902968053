import React, { useState } from "react";

import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useResponsive from "../../hook/useResponsive";
import BlockFormModal from "../BlockFormModal";
import ConfirmDialog from "../modals/ConfirmDialog";

const AddEditFormButtonGroup = ({ action, handleClose, confirmation }) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { handleClearFields } = useFormContext();

  const isMobile = useResponsive("down", "md");

  return (
    <>
      <Grid item sx={{ width: "100%", mb: 2 }}>
        {isMobile ? (
          <Stack
            alignItems="center"
            direction="column"
            justifyContent="center"
            spacing={2}
            sx={{ width: "100%", mt: 3 }}
          >
            <Button sx={{ width: "100%" }} type="submit" variant="contained">
              {action === "Add"
                ? t("src.components.Intelligence.add")
                : t("main.another.save")}
            </Button>
            <Button
              onClick={handleClearFields}
              sx={{ width: "100%" }}
              variant="outlined"
            >
              {action === "Add"
                ? t("src.components.Intelligence.clear")
                : t("src.components.Intelligence.reset")}
            </Button>
          </Stack>
        ) : (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <Button type="submit" variant="contained">
                {action === "Add"
                  ? t("src.components.Intelligence.add")
                  : t("main.another.save")}
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  !confirmation ? handleClose() : setDialogOpen(true);
                }}
                sx={{ ml: 1 }}
                variant="text"
              >
                {t("src.components.Intelligence.close")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={handleClearFields}
                sx={{ ml: 1 }}
                variant="text"
              >
                {action === "Add"
                  ? t("src.components.Intelligence.clear")
                  : t("src.components.Intelligence.reset")}
              </Button>
            </Grid>
          </Stack>
        )}
        <ConfirmDialog
          isActive={dialogOpen}
          onConf={handleClose}
          setDialogOpen={setDialogOpen}
        />
      </Grid>
    </>
  );
};

export default AddEditFormButtonGroup;
