import React from "react";

import { Avatar, Box, Grid, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { StyledAccount } from "../../theme/standarts_styles";

const AccountCompany = () => {
  const { t } = useTranslation();
  const { openNav } = useSelector((state) => state.serviceReducer);

  return (
    <Link underline="none">
      <StyledAccount>
        {openNav ? (
          <Box
            sx={{
              px: 2,
              py: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="photoURL"
              src="/assets/images/covers/aspex_cover.png"
            />
            <Box sx={{ ml: 2 }}>
              <Typography color="layout.main" variant="subtitle1">
                {t("src.layouts.dashboard.nav.index.companyName")}
              </Typography>
              <Typography color="layout.main" variant="body1">
                {t("src.layouts.dashboard.nav.index.companyID")}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Avatar
              alt="photoURL"
              src="/assets/images/covers/aspex_cover.png"
              sx={{
                width: "30px",
                height: "30px",
              }}
            />
          </Grid>
        )}
      </StyledAccount>
    </Link>
  );
};

export default AccountCompany;
