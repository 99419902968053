import React from "react";

import { useRoutes } from "react-router-dom";

import MyContracts from "./components/Account/MyContracts";
import MyContractView from "./components/Account/MyContractView";
import UserData from "./components/Account/UserData";
import ApproveSchedule from "./components/ApproveSchedule";
import Contacts from "./components/Contacts";
import Departments from "./components/OrgStructure/Departments";
import Employees from "./components/OrgStructure/Employees";
import ProdCalen from "./components/ProdCalen";
import Budgets from "./components/ProjectsEditComponents/Budgets";
import CommonSettings from "./components/ProjectsEditComponents/CommonSettings";
import CommonSettingsView from "./components/ProjectsEditComponents/CommonSettingsView";
import Planning from "./components/ProjectsEditComponents/Planning";
import MySchedule from "./components/Schedule/MySchedule";
import { AuthProvider } from "./hoc/authProvider";
import RequareAuth from "./hoc/requreAuth";
import DashboardLayout from "./layouts/DashboardLayout";
import Account from "./pages/Account";
import Clients from "./pages/Clients";
import ClientsEditPage from "./pages/ClientsEditPage";
import ClientsViewPage from "./pages/ClientsViewPage";
import Contracts from "./pages/Contracts/Contracts";
import UserContractAdd from "./pages/Contracts/UserContractAdd";
import UserContractEdit from "./pages/Contracts/UserContractEdit";
import UserContracts from "./pages/Contracts/UserContracts";
import ContractsNewPage from "./pages/ContractsNewPage";
import DownloadReports from "./pages/DownloadReports";
import EmployeePage from "./pages/EmployeePage";
import ClientsAddForm from "./pages/forms/ClientsAddForm";
import ClientsForm from "./pages/forms/ClientsForm";
import ClientsFormView from "./pages/forms/ClientsFormView";
import DepartmentsForm from "./pages/forms/DepartmentsForm";
import EmployeesForm from "./pages/forms/EmployeesForm";
import ProjectsAddForm from "./pages/forms/ProjectsAddForm";
import ProjectsEditForm from "./pages/forms/ProjectsEditForm";
import Login from "./pages/Login";
import MainPage from "./pages/MainPage";
import OrgStructure from "./pages/OrgStructure";
import Projects from "./pages/Projects";
import ProjectViewPage from "./pages/ProjectViewPage";
import Schedule from "./pages/Schedule";
import ApprovalStatement from "./pages/Statements/ApprovalStatement";
import MyStatement from "./pages/Statements/MyStatement";
import TimesheetMonth from "./pages/Timesheets/TimesheetMonth";
import TimesheetQuarter from "./pages/Timesheets/TimesheetQuarter";
import Timesheets from "./pages/Timesheets/Timesheets";
import TimesheetYear from "./pages/Timesheets/TimesheetYear";
import LoginSuccess from "./sections/auth/login/LoginSuccess";
import { store } from "./store/store";

export const getRoutes = () => {
  return [
    {
      path: "/",
      element: (
        <AuthProvider>
          <DashboardLayout />
        </AuthProvider>
      ),
      roles: ["ERP_Users", "ERP_Admins"],
      children: [
        {
          path: "",
          element: (
            <RequareAuth>
              <MainPage />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
        },
        {
          path: "clients",
          searchText: "Клиенты",
          element: (
            <RequareAuth>
              <Clients />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
        },
        {
          path: "clients/edit/:clientId",
          element: (
            <RequareAuth>
              <ClientsEditPage />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
          children: [
            {
              path: "general",
              element: (
                <RequareAuth>
                  <ClientsForm />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
            {
              path: "contacts",
              element: (
                <RequareAuth>
                  <Contacts />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
          ],
        },
        {
          path: "clients/:clientId",
          element: (
            <RequareAuth>
              <ClientsViewPage />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
          children: [
            {
              path: "general",
              element: (
                <RequareAuth>
                  <ClientsFormView />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
            {
              path: "contacts",
              element: (
                <RequareAuth>
                  <Contacts />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
          ],
        },
        {
          path: "clients/new",
          element: (
            <RequareAuth>
              <ClientsAddForm />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
        },
        {
          path: "projects",
          searchText: "Проекты",
          element: (
            <RequareAuth>
              <Projects />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
        },
        {
          path: "projects/new",
          element: (
            <RequareAuth>
              <ProjectsAddForm />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
        },
        {
          path: "projects/:projectId",
          element: (
            <RequareAuth>
              <ProjectViewPage />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
          children: [
            {
              path: "general",
              element: (
                <RequareAuth>
                  <CommonSettingsView />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
            {
              path: "budgets",
              element: (
                <RequareAuth>
                  <Budgets />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
            {
              path: "planning",
              element: (
                <RequareAuth>
                  <Planning />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
          ],
        },
        {
          path: "projects/edit/:projectId",
          element: (
            <RequareAuth>
              <ProjectsEditForm />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
          children: [
            {
              path: "general",
              element: (
                <RequareAuth>
                  <CommonSettings />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
            {
              path: "budgets",
              element: (
                <RequareAuth>
                  <Budgets />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
            {
              path: "planning",
              element: (
                <RequareAuth>
                  <Planning />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
          ],
        },
        {
          path: "schedule",
          searchText: "Расписание",
          element: (
            <RequareAuth>
              <Schedule />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
          children: [
            {
              path: "my-schedule",
              element: (
                <RequareAuth>
                  <MySchedule />
                </RequareAuth>
              ),
              roles: ["ERP_Admins"],
            },
            {
              path: "statements-approval",
              element: (
                <RequareAuth>
                  <ApproveSchedule admin={true} id={2} />
                </RequareAuth>
              ),
              roles: ["ERP_Admins"],
            },
            {
              path: "statements-approval/:statementId",
              element: (
                <RequareAuth>
                  <ApprovalStatement />
                </RequareAuth>
              ),
              roles: ["ERP_Admins"],
            },
            {
              path: "my-statements",
              element: (
                <RequareAuth>
                  <ApproveSchedule admin={false} id={1} />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
            {
              path: "my-statements/:statementId",
              element: (
                <RequareAuth>
                  <MyStatement />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
          ],
        },
        {
          path: "timesheets",
          searchText: "Табели",
          element: (
            <RequareAuth>
              <Timesheets />
            </RequareAuth>
          ),
          roles: ["ERP_Admins"],
          children: [
            {
              path: "month",
              element: (
                <RequareAuth>
                  <TimesheetMonth />
                </RequareAuth>
              ),
              roles: ["ERP_Admins"],
            },
            {
              path: "quarter",
              element: (
                <RequareAuth>
                  <TimesheetQuarter />
                </RequareAuth>
              ),
              roles: ["ERP_Admins"],
            },
            {
              path: "year",
              element: (
                <RequareAuth>
                  <TimesheetYear />
                </RequareAuth>
              ),
              roles: ["ERP_Admins"],
            },
          ],
        },
        {
          path: "contracts",
          searchText: "Договоры",
          element: (
            <RequareAuth>
              <Contracts />
            </RequareAuth>
          ),
          roles: ["ERP_Admins"],
        },
        {
          path: "contracts/:contractId",
          element: (
            <RequareAuth>
              <UserContracts />
            </RequareAuth>
          ),
          roles: ["ERP_Admins"],
        },
        {
          path: "contracts/:contractId/edit",
          element: (
            <RequareAuth>
              <UserContractEdit />
            </RequareAuth>
          ),
          roles: ["ERP_Admins"],
        },
        {
          path: "contracts/new",
          element: (
            <RequareAuth>
              <UserContractAdd />
            </RequareAuth>
          ),
          roles: ["ERP_Admins"],
        },
        {
          path: "account",
          searchText: "Профиль",
          element: (
            <RequareAuth>
              <Account />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
          children: [
            {
              path: "general",
              element: (
                <RequareAuth>
                  <UserData />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
            {
              path: "contracts",
              element: (
                <RequareAuth>
                  <MyContracts />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
          ],
        },
        {
          path: "account/contracts/:contractId",
          element: (
            <RequareAuth>
              <MyContractView />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
        },
        {
          path: "org-structure",
          searchText: "Орг. структура",
          element: (
            <RequareAuth>
              <OrgStructure />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
          children: [
            {
              path: "employees",
              element: (
                <RequareAuth>
                  <Employees />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
            {
              path: "departments",
              element: (
                <RequareAuth>
                  <Departments />
                </RequareAuth>
              ),
              roles: ["ERP_Users", "ERP_Admins"],
            },
          ],
        },
        {
          path: "org-structure/employees/edit/:employeeId",
          element: (
            <RequareAuth>
              <EmployeesForm />
            </RequareAuth>
          ),
          roles: ["ERP_Admins"],
        },
        {
          path: "org-structure/employees/:employeeId",
          element: (
            <RequareAuth>
              <EmployeePage />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
        },
        {
          path: "org-structure/employees/edit/:employeeId/contracts/new",
          element: (
            <RequareAuth>
              <ContractsNewPage />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
        },
        {
          path: "org-structure/departments/new",
          element: (
            <RequareAuth>
              <DepartmentsForm />
            </RequareAuth>
          ),
          roles: ["ERP_Admins"],
        },
        {
          path: "download-reports",
          element: (
            <RequareAuth>
              <DownloadReports />
            </RequareAuth>
          ),
          roles: ["ERP_Users", "ERP_Admins"],
        },
        // {
        //   path: 'calendar',
        //   element: (
        //     <RequareAuth>
        //       <ProdCalen/>
        //     </RequareAuth>
        //   ),
        //   roles: [
        //     'ERP_Users',
        //     'ERP_Admins'
        //   ],
        // },
        {
          path: "auth/success",
          element: <LoginSuccess />,
        },
        {
          path: "login",
          element: <Login />,
        },
      ],
    },
  ];
};

// export default function Router() {
//   const routes = getRoutes();
//
//   return useRoutes(routes);
// }

export const getFilteredRoutes = () => {
  const { locked_flags, roles } = store.getState().authReducer;
  const routes = getRoutes();

  const filterRoutesRecursively = (route) => {
    if (route.roles && route.roles.length > 0) {
      const shouldIncludeWithoutRoles =
        roles === null && route.roles?.some((r) => r === "WithoutRoles"); // отображаем рут если роль пользователя отсутствует и в route есть роль 'WithoutRoles'
      const shouldIncludeWithRoles = route.roles?.some((r) =>
        roles?.some((userRole) => r === userRole),
      ); // отображаем рут если пользователь имеем хотя бы одну роль, которая совпадает с route ролью
      const hasMatchingLockedFlag = route.locked_flags?.some(
        (flag) => locked_flags?.name === flag.name,
      ); // отображаем рут если пользователь имеем locked_flags, который совпадает с route locked_flags

      if (
        (shouldIncludeWithoutRoles || shouldIncludeWithRoles) &&
        (!locked_flags || hasMatchingLockedFlag)
      ) {
        if (Array.isArray(route.children)) {
          const filteredChildren = route.children
            .map((childRoute) => filterRoutesRecursively(childRoute))
            .filter(Boolean);
          return { ...route, children: filteredChildren };
        }
        return route;
      }
    }
    return null;
  };

  return routes.map((route) => filterRoutesRecursively(route)).filter(Boolean);
};

export default function Router() {
  const filteredRoutes = getFilteredRoutes();

  // const routes = getRoutes();

  return useRoutes(filteredRoutes);
}
