import React, { useEffect } from "react";

import { Grid } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";

const CustomDateRangePicker = ({
  startName,
  endName,
  startLabel,
  endLabel,
  rules,
  disablePast = false,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const startError = errors[startName];
  const endError = errors[endName];
  const req = !!rules?.required;

  const containerStyle = {
    position: "relative",
    marginBottom: "12px",
    width: "100%",
  };

  const spanStyle = {
    position: "absolute",
    bottom: "4px",
    fontSize: "12px",
    color: "red",
    left: 14,
    width: "100%",
  };
  const dividerStyle = {
    fontSize: "1.5em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "24px",
  };
  return (
    <Grid container style={containerStyle}>
      <Grid item xs={5.9}>
        <Controller
          control={control}
          name={startName}
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                control={control}
                disablePast={disablePast}
                format="DD.MM.YYYY"
                label={`${startLabel}${req ? " *" : ""}`}
                onChange={(newValue) => {
                  setValue(endName, null, { shouldDirty: true });
                  setValue(startName, newValue, { shouldDirty: true });
                }}
                value={
                  getValues(startName) ? dayjs(getValues(startName)) : null
                }
                {...rest}
                slotProps={{
                  textField: {
                    InputLabelProps: { shrink: true },
                    size: rest.size || "medium",
                    error: !!endError || !!startError,
                  },
                }}
              />
            </LocalizationProvider>
          )}
          rules={rules}
        />
      </Grid>

      <Grid item style={dividerStyle} xs={0.2}>
        <div>-</div>
      </Grid>

      <Grid item xs={5.9}>
        <Controller
          control={control}
          name={endName}
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                control={control}
                disablePast={disablePast}
                format="DD.MM.YYYY"
                label={`${endLabel}${req ? " *" : ""}`}
                minDate={!rest.disabled ? getValues(startName) : null}
                onChange={(newValue) => {
                  setValue(endName, newValue, { shouldDirty: true });
                }}
                value={getValues(endName) ? dayjs(getValues(endName)) : null}
                {...rest}
                slotProps={{
                  textField: {
                    InputLabelProps: { shrink: true },
                    size: rest.size || "medium",
                    error: !!endError || !!startError,
                  },
                }}
              />
            </LocalizationProvider>
          )}
          rules={{
            ...rules,
            validate: (value) => {
              const startDate = getValues(startName);
              const endDate = value;
              return (
                (startDate && endDate && startDate <= endDate) ||
                "Дата окончания не может быть раньше даты начала"
              );
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <span style={spanStyle}>
          {(!!endError || !!startError) &&
            (startError
              ? startError.message
              : endError
                ? endError.message
                : "Unknown error!")}
        </span>
      </Grid>
    </Grid>
  );
};

export default CustomDateRangePicker;
