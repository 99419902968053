import React, { useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import LoadingSpinner from "../components/modals/loadingSpinner";
import CustomTextField from "../components/TextFields/CustomTextField";
import { TitleStack } from "../theme/standarts_styles";

const ContractsNewPage = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedEmployee } = useSelector((state) => state.employeesReducer);
  const { employeeId } = useParams();

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful },
    control,
    setError,
    clearErrors,
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      console.log(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(`/org-structure/employees/edit/${employeeId}/contracts`);
  };

  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">
          Управление договорными отношениями для{" "}
          {selectedEmployee?.display_name}
        </Typography>
      </TitleStack>
      <Box sx={{ flexDirection: "column", px: 0, py: 4 }}>
        {loading && <LoadingSpinner />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="start_date"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    control={control}
                    label="Дата начало"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                        size: "small",
                      },
                    }}
                    sx={{
                      width: "100%",
                      mb: 3,
                    }}
                    value={value}
                  />
                </LocalizationProvider>
              )}
              rules={
                {
                  // required: 'Обязательное поле',
                }
              }
            />
            <Controller
              control={control}
              name="end_date"
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    control={control}
                    label="Дата конец"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: error?.message,
                        size: "small",
                      },
                    }}
                    sx={{
                      width: "100%",
                      mb: 3,
                    }}
                    value={value}
                  />
                </LocalizationProvider>
              )}
              rules={
                {
                  // required: 'Обязательное поле',
                }
              }
            />
            <Controller
              control={control}
              name="type"
              render={({
                field: { onChange, ...field },
                fieldState: { error },
              }) => {
                return (
                  <Autocomplete
                    {...field}
                    getOptionLabel={(option) => option}
                    onChange={(e, value) => onChange(value)}
                    options={[
                      "Core-full",
                      "Core-fix",
                      "Contractor",
                      "Associate",
                    ]}
                    renderInput={(params) => (
                      <TextField
                        label="Тип отношений"
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                    size="small"
                    sx={{
                      mb: 3,
                    }}
                  />
                );
              }}
              rules={
                {
                  // required: 'Обязательное поле',
                }
              }
            />
            <CustomTextField
              label="Оклад"
              name="oklad"
              registerOptions={{
                required: t("main.another.requiredField"),
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Ставка"
              name="stavka"
              registerOptions={{
                required: t("main.another.requiredField"),
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Объем часов"
              name="hours"
              registerOptions={{
                required: t("main.another.requiredField"),
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Ежегодный бюджет на отпуск"
              name="budget1"
              registerOptions={{
                required: t("main.another.requiredField"),
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Ежегодный бюджет на платные отгулы"
              name="budget2"
              registerOptions={{
                required: t("main.another.requiredField"),
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item>
                <Button type="submit" variant="contained">
                  Сохранить
                </Button>
                <Button
                  color="primary"
                  onClick={handleBack}
                  sx={{ ml: 1 }}
                  variant="text"
                >
                  Назад
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => reset()}
                  sx={{ ml: 1 }}
                  variant="text"
                >
                  Сбросить
                </Button>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Container>
  );
};

export default ContractsNewPage;
