import React, { useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Card, IconButton, Tooltip, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AlertMessage from "../components/Alert/AlertMessage";
import BlockFormModal from "../components/BlockFormModal";
import Iconify from "../components/iconify";
import MaterialTablePagination from "../components/MaterialTablePagination";
import LoadingSpinner from "../components/modals/loadingSpinner";
import { TableCellsWrap } from "../components/TableCellsWrap";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";
import {
  ButtonStack,
  RowOptionsStack,
  TitleStack,
} from "../theme/standarts_styles";
import { getSortingString } from "../utils/getSortingString";

const Clients = () => {
  const tableId = "clients";
  const tableStates = useSelector((state) => state.tableStatesReducer);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setClients, setSelectedClient } = useActions();
  const [confirmationId, setConfirmationId] = useState(null);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);
  const isMobile = useResponsive("down", "md");

  const { roles } = useSelector((state) => state.authReducer);

  const { clients, selectedClient } = useSelector(
    (state) => state.clientsReducer,
  );

  const { setErrorAlertMessage, resetErrorAlertMessage } = useActions();

  const tableColumns = [
    {
      accessorKey: "name",
      header: "Наименование",
      accessorFn: (row) => row.name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "full_name",
      header: "Полное наименование",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "code",
      header: "Код клиента",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'TIN',
    //   header: 'TIN',
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: "address",
      header: "Адрес",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "web_site",
      header: "Веб-сайт",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'phone',
    //   header: 'Телефон',
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
    {
      accessorKey: "country",
      header: "Страна",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "details",
      header: "Реквизиты",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.source_id) {
          return (
            <>
              <Tooltip title="Подтвердить">
                <IconButton
                  color="inherit"
                  onClick={() => handleDeleteRow(row.original.source_id)}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Отмена">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(null)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <RowOptionsStack>
            <Tooltip title="Редактировать">
              <IconButton
                color="inherit"
                onClick={() => handleEditRow(row.original.source_id)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {roles?.some((role) => role === "ERP_Admins") && (
              <Tooltip title="Удалить">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(row.original.source_id)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </RowOptionsStack>
        );
      },
    },
  ];

  const handleAddClient = (event) => {
    navigate(`/clients/new`);
  };

  const handleEditRow = (id) => {
    setSelectedClient(id);
    navigate(`/clients/edit/${id}`);
  };

  const handleOpenViewPage = (row) => {
    const id = row.original.source_id;
    if (id) {
      navigate(`/clients/${id}`);
      setSelectedClient(id);
    }
  };

  async function handleDeleteRow(id) {
    setLoading(true);
    resetErrorAlertMessage();
    try {
      await $authHost.delete(`/clients/${id}/delete/`).then((response) => {
        loadData();
      });
    } catch (e) {
      if (
        e.response.data?.detail === "Cannot delete client with active projects"
      ) {
        setErrorAlertMessage(
          "Для данного клиента существуют связанные проекты. Удаление невозможно.",
        );
      }
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  }

  const loadData = useCallback(
    async (search, index, size, sorting) => {
      setLoading(true);

      try {
        const sortStr = getSortingString(sorting);

        const response = await $authHost.get(`/clients/all/`, {
          params: {
            page: index + 1,
            size: size,
            ...(search && { search: search }),
            ...(sortStr && { sort_by: sortStr }),
          },
        });

        const data = response.data.items;
        setClients(data);
        setRowCount(response.data?.total);
        setPageCount(response.data?.pages);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setClients],
  );

  useEffect(() => {
    resetErrorAlertMessage();
  }, [resetErrorAlertMessage]);

  useEffect(() => {
    loadData(searchText, pagination.pageIndex, pagination.pageSize, sorting);
  }, [
    loadData,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    sorting,
  ]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  return (
    <Container>
      <TitleStack>
        <Typography sx={{ mr: 4 }} variant="h4">
          Клиенты
        </Typography>
      </TitleStack>
      <ButtonStack>
        <Button
          aria-haspopup="true"
          onClick={handleAddClient}
          size={isMobile ? "small" : "medium"}
          startIcon={<Iconify icon="eva:plus-fill" />}
          variant="contained"
        >
          Новый клиент
        </Button>
      </ButtonStack>
      <AlertMessage />
      <Card sx={{ pt: 1 }}>
        <MaterialTablePagination
          columns={tableColumns}
          data={clients}
          id={tableId}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
      <BlockFormModal
        title="Вы не завершили действие внутри таблицы"
        when={!!confirmationId}
      />
    </Container>
  );
};

export default Clients;
