import { useState } from "react";

import { TextField } from "@mui/material";

import { getRoutes } from "../../routes";
import { SearchInput, StyledLink } from "../../theme/standarts_styles";

const SearchFieldInAppBar = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  const routes = getRoutes();
  const searchList = [];

  const forEachArr = (arr) => {
    arr.forEach((item) => {
      if (item.searchText) {
        searchList.push(item);
      }
      if (Array.isArray(item.children)) {
        forEachArr(item.children);
      }
    });
  };

  forEachArr(routes);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SearchInput
          blurOnSelect={true}
          disablePortal
          forcePopupIcon={false}
          getOptionLabel={(option) => option.searchText}
          inputValue={inputValue}
          isOptionEqualToValue={(option, value) => option.searchText === value}
          noOptionsText="Нет доступных вариантов"
          onChange={(event, newValue) => {
            setAutocompleteValue(null);
            setInputValue("");
          }}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          options={searchList}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                sx: {
                  color: "text.primary",
                },
                ...params.InputProps,
              }}
              placeholder="Search..."
              type="search"
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledLink
                sx={{ zIndex: "50000 !important" }}
                to={`/${option.path}`}
              >
                {option.searchText}
              </StyledLink>
            </li>
          )}
          size="small"
          sx={{
            width: `${props.width}`,
          }}
          value={autocompleteValue}
        />
      </div>
    </>
  );
};

export default SearchFieldInAppBar;
