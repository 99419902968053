import React, { useCallback, useEffect, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";
import Alert from "@mui/material/Alert";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useActions } from "../../../hook/useActions";
import { $authHost } from "../../../http";
import { getLocalizedForDropdownValues } from "../../../utils/getLocalizedForDropdownValues";
import CustomDatepicker from "../../TextFields/CustomDatepicker";
import CustomDateRangePicker from "../../TextFields/CustomDateRangePicker";
import CustomMultiAutocompleteForObjectLocalized from "../../TextFields/CustomMultiAutocompleteForObjectLocalized";
import CustomTextField from "../../TextFields/CustomTextField";

const LeaveApplicationFields = ({ openForm }) => {
  const { t } = useTranslation();
  const { roles } = useSelector((state) => state.authReducer);
  const { users } = useSelector((state) => state.usersReducer);
  const { setUsers } = useActions();

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [payerOptions, setPayerOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  const [selectedTypeOption, setSelectedTypeOption] = useState(null);

  const loadData = useCallback(async () => {
    try {
      const [payerOptionsResponse, typeOptionsResponse, usersResponse] =
        await Promise.all([
          $authHost.get(`/dropdowns/payer_options/`),
          $authHost.get(`/dropdowns/type_options/`),
          $authHost.get(`/users/all/`),
        ]);
      setPayerOptions(payerOptionsResponse.data.dropdown);
      setTypeOptions(typeOptionsResponse.data.dropdown);
      setUsers(usersResponse.data.items);
    } catch (e) {
      console.log(e);
    }
  }, [setUsers]);

  const FilterPayOptionForTypes = (options) => {
    let baseRule = [
      {
        original_name: "company_expense",
        available_types: ["vacation", "sick_leave", "overtime_work_days"],
      },
      {
        original_name: "self_expense",
        available_types: ["vacation", "sick_leave"],
      },
      {
        original_name: "overwork_expense",
        available_types: ["day_off"],
      },
    ];
    let available_original_name = baseRule.filter((el) =>
      el.available_types.includes(selectedTypeOption),
    );
    return options.filter((el) =>
      available_original_name
        .map((x) => x.original_name)
        .includes(el.original_name),
    );
  };

  useEffect(() => {
    // if (openForm !== 'View') {
    loadData();
    // }
  }, [loadData]);

  return (
    <>
      <CustomMultiAutocompleteForObjectLocalized
        disabled={openForm === "View"}
        getoptionlabel={(option) => option.name}
        label="Тип заявления"
        name="type"
        onChangeValue={(e) => {
          setSelectedTypeOption(e);
          setValue("payer", null);
          setValue("start_date", null);
          setValue("end_date", null);
          setValue("users", null);
          setValue("request_text", null);
        }}
        options={getLocalizedForDropdownValues(typeOptions, "type")}
        rules={{
          required: openForm !== "View" && t("main.another.requiredField"),
        }}
        size="small"
        sx={{ width: "100%", fontSize: "12px", mb: 3 }}
      />
      {selectedTypeOption !== "fired" && (
        <CustomMultiAutocompleteForObjectLocalized
          disabled={openForm === "View"}
          label="За чей счет"
          name="payer"
          onChangeValue={(e) => {}}
          options={
            openForm === "View"
              ? getLocalizedForDropdownValues(payerOptions, "payer")
              : FilterPayOptionForTypes(
                  getLocalizedForDropdownValues(payerOptions, "payer"),
                )
          }
          rules={{
            required:
              selectedTypeOption !== "fired" &&
              openForm !== "View" &&
              t("main.another.requiredField"),
          }}
          size="small"
          sx={{ width: "100%", fontSize: "12px", mb: 3 }}
        />
      )}
      {selectedTypeOption !== "fired" && (
        <CustomDateRangePicker
          disablePast={true}
          disabled={openForm === "View"}
          endLabel="Дата окончания"
          endName="end_date"
          rules={{
            required:
              selectedTypeOption !== "fired" &&
              openForm !== "View" &&
              t("main.another.requiredField"),
          }}
          size="small"
          startLabel="Дата начала"
          startName="start_date"
          sx={{ width: "100%", fontSize: "12px", mb: 3 }}
        />
      )}
      {selectedTypeOption === "fired" && (
        <CustomDatepicker
          disablePast={true}
          disabled={openForm === "View"}
          format="DD.MM.YYYY"
          label="Дата увольнения"
          name="start_date"
          rules={{
            required:
              selectedTypeOption === "fired" &&
              openForm !== "View" &&
              t("main.another.requiredField"),
          }}
          size="small"
          sx={{ width: "100%", fontSize: "12px", mb: 3 }}
        />
      )}

      {selectedTypeOption === "fired" &&
        roles?.some((role) => role === "ERP_Admins") && (
          <>
            <Controller
              control={control}
              name="users"
              render={({
                field: { onChange, ...field },
                fieldState: { error },
              }) => {
                return (
                  <Autocomplete
                    {...field}
                    getOptionLabel={(option) => option?.display_name || ""}
                    onChange={(e, value) => {
                      onChange(value);
                    }}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        label="Сотрудник *"
                        {...params}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!error}
                        helperText={error?.message}
                        sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                      />
                    )}
                    size="small"
                  />
                );
              }}
              rules={{
                required:
                  selectedTypeOption === "fired" &&
                  roles?.some((role) => role === "ERP_Admins") &&
                  openForm !== "View" &&
                  t("main.another.requiredField"),
              }}
            />
            <Alert severity="info" sx={{ mb: 3 }}>
              Обратите внимание, что заявлению на увольнение сотрудника,
              созданному Администратором, не требуется согласование, оно будет
              автоматически принято.
            </Alert>
          </>
        )}
      <CustomTextField
        disabled={openForm === "View"}
        label="Текст запроса"
        multiline={true}
        name="request_text"
        registerOptions={{
          required: openForm !== "View" && t("main.another.requiredField"),
        }}
        rows={3}
        size="small"
        sx={{ width: "100%", fontSize: "12px", mb: 3 }}
      />
    </>
  );
};

export default LeaveApplicationFields;
