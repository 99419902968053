import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statistics: {
    teams_tracked_hours: null,
    clickup_tracked_hours: null,
    plan_month_hours: null,
    vacation_days: null,
    plan_vacation_days: null,
    sick_days: null,
    plan_sick_days: null,
    overworked_days: null,
    summarized_clickup_trackers: [],
    clickup_closed_tasks: [],
    clickup_day_trackers: [],
  },
};

const StatisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setStatistics(state, { payload }) {
      state.statistics = payload;
    },
  },
});

export const { actions: statisticsActions, reducer: statisticsReducer } =
  StatisticsSlice;
export default StatisticsSlice.reducer;
