import React, { useCallback, useEffect, useMemo, useState } from "react";

import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import {
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import {
  LocalizationProvider,
  MonthCalendar,
  YearCalendar,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import MaterialTable from "../components/MaterialTable";
import ProdCalen from "../components/ProdCalen";
import { TableCellsWrap } from "../components/TableCellsWrap";
import { TIMESHEET_STATUSES } from "../constants";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";
import { TitleStack } from "../theme/standarts_styles";

const LinearProgressWithLabel = ({ label, currentValue, totalValue }) => {
  const progress = (currentValue / totalValue) * 100;

  return (
    <Stack alignItems="center">
      <Typography>{label}</Typography>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{ width: "100%" }}
      >
        <Typography sx={{ minWidth: 30 }} textAlign="end" variant="subtitle1">
          {currentValue}
        </Typography>
        <LinearProgress
          sx={{ height: 16, width: "100%" }}
          value={progress > 100 ? 100 : progress}
          variant="determinate"
        />
        <Typography sx={{ minWidth: 30 }} variant="subtitle1">
          {totalValue}
        </Typography>
      </Stack>
    </Stack>
  );
};

const MainPage = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingTimesheet, setLoadingTimesheet] = useState(false);
  const [users, setUsers] = useState([]);
  const [timesheet, setTimesheet] = useState({});
  const [monthCalendar, setMonthCalendar] = useState(dayjs().format("M"));
  const [month, setMonth] = useState(dayjs());
  const [year, setYear] = useState(dayjs());
  const [anchorMonth, setAnchorMonth] = useState(null);
  const [anchorYear, setAnchorYear] = useState(null);
  const { display_name } = useSelector((state) => state.authReducer);
  const { statistics } = useSelector((state) => state.statisticsReducer);
  const { setStatistics } = useActions();
  const isMobile = useResponsive("down", "md");

  const {
    teams_tracked_hours,
    clickup_tracked_hours,
    plan_month_hours,
    vacation_days,
    plan_vacation_days,
    sick_days,
    plan_sick_days,
    overworked_days,
    summarized_clickup_trackers = [],
    clickup_closed_tasks = [],
    clickup_day_trackers = [],
  } = statistics;

  const loadStatistics = useCallback(
    async (month, year) => {
      setLoading(true);

      try {
        const selectedMonth = dayjs(month).month() + 1;
        const selectedYear = dayjs(year).year();

        const response = await $authHost.get(
          `/statistics/me/?month=${selectedMonth}&year=${selectedYear}`,
        );

        setStatistics(response.data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setStatistics],
  );

  const loadUsers = useCallback(async (monthCalendar) => {
    setLoading(true);

    try {
      const response = await $authHost.get(
        `/users/all/birthday/?month=${monthCalendar}`,
      );

      setUsers(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadTimesheet = useCallback(async (month, year) => {
    setLoadingTimesheet(true);

    try {
      const selectedMonth = dayjs(month).month() + 1;
      const selectedYear = dayjs(year).year();

      const response = await $authHost.get(
        `/report_cards/me/?year=${selectedYear}&month=${selectedMonth}&is_calendar_with_month=false`,
      );
      setTimesheet([response.data]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTimesheet(false);
    }
  }, []);

  useEffect(() => {
    loadStatistics(month, year);
    loadTimesheet(month, year);
  }, [loadStatistics, loadTimesheet, month, year]);

  useEffect(() => {
    loadUsers(monthCalendar);
  }, [loadUsers, monthCalendar]);

  const options1 = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      // width: 3,
    },
    grid: {
      // borderColor: '#e7e7e7',
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      // categories: days,
      // title: {
      //   text: 'Дни'
      // },
      // type: 'category',
      // type: 'numeric',
      type: "datetime",
      // tickAmount: 'dataPoints',
      // tickPlacement: 'between',
      labels: {
        format: "d MMM",
        formatter: function (value, timestamp) {
          return dayjs(timestamp).format("D MMM");
        },
      },
    },
    yaxis: {
      title: {
        text: "Часы",
      },
      decimalsInFloat: 2,
      // min: 0,
      // max: 120
    },
    noData: {
      text: "Нет данных для отображения",
      style: {
        color: "#000000",
        fontSize: "16px",
      },
    },
  };

  const series1 = [
    {
      name: "Кол-во часов",
      data: summarized_clickup_trackers,
      color: "#2065D1",
    },
  ];

  const options2 = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 2],
      curve: ["smooth", "stepline"],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "d MMM",
        formatter: function (value, timestamp) {
          return dayjs(timestamp).format("D MMM");
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Закрытые задачи",
        },
        decimalsInFloat: 2,
      },
      {
        opposite: true,
        title: {
          text: "Часы",
        },
        decimalsInFloat: 2,
      },
    ],
    noData: {
      text: "Нет данных для отображения",
      style: {
        color: "#000000",
        fontSize: "16px",
      },
    },
  };

  const series2 = [
    {
      name: "Закрытые задачи",
      type: "column",
      data: clickup_closed_tasks,
      color: "#aac4ed",
    },
    {
      name: "Часы",
      type: "line",
      data: clickup_day_trackers,
      color: "#2065D1",
    },
  ];

  const handleClickMonth = (event) => {
    setAnchorMonth(event.currentTarget);
  };

  const handleCloseMonth = () => {
    setAnchorMonth(null);
  };

  const handleClickYear = (event) => {
    setAnchorYear(event.currentTarget);
  };

  const handleCloseYear = () => {
    setAnchorYear(null);
  };

  const handleNext = () => {
    const nextMonth = dayjs(month).add(1, "month");

    if (dayjs(nextMonth).month() === 0 && dayjs(month).month() === 11) {
      const nextYear = dayjs(year).add(1, "year");
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const handlePrev = () => {
    const nextMonth = dayjs(month).subtract(1, "month");

    if (dayjs(nextMonth).month() === 11 && dayjs(month).month() === 0) {
      const nextYear = dayjs(year).subtract(1, "year");
      setYear(nextYear);
    }

    setMonth(nextMonth);
  };

  const days = useMemo(() => {
    const daysInMonth = dayjs(month).daysInMonth();
    const daysArr = [];

    for (let i = 1; i <= daysInMonth; i++) {
      daysArr.push(i);
    }

    return daysArr;
  }, [month]);

  const tableColumns = [
    ...days.map((d) => ({
      accessorKey: `calendar.${d.toString()}`,
      header: d.toString(),
      size: 1,
      muiTableBodyCellProps: ({ cell, table }) => {
        const value = cell.getValue();
        const status = TIMESHEET_STATUSES.find(
          (status) => status.value === value,
        );
        if ((status && !status?.editing) || !status) {
          return {
            sx: {
              backgroundColor: `${status?.color} !important`,
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              cursor: "default",
            },
          };
        } else {
          return {
            sx: {
              backgroundColor: `${status?.color} !important`,
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
              borderRight: "1px solid rgba(224, 224, 224, 1)",
            },
          };
        }
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    })),
  ];

  return (
    <Container sx={{ pb: 0 }}>
      <TitleStack>
        <Typography gutterBottom variant="h4">
          Главная
        </Typography>
      </TitleStack>
      <Stack pb={2} spacing={2}>
        <Card sx={{ p: isMobile ? 1 : 2 }}>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography variant="h5">Мои данные:</Typography>
                <Stack pt={2} px={2} spacing={1}>
                  <Typography pb={1} variant="h6">
                    {display_name}
                  </Typography>
                  <LinearProgressWithLabel
                    currentValue={clickup_tracked_hours}
                    label="Количество затреканных часов в месяц"
                    totalValue={plan_month_hours}
                  />
                  <LinearProgressWithLabel
                    currentValue={vacation_days}
                    label="Количество отпускных дней"
                    totalValue={plan_vacation_days}
                  />
                  <LinearProgressWithLabel
                    currentValue={sick_days}
                    label="Количество оплачиваемых больничных"
                    totalValue={plan_sick_days}
                  />
                  <Typography textAlign="center">
                    Количество переработок: <b>{overworked_days}</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography pb={2} variant="h5">
                  Производственный календарь:
                </Typography>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  mb={2}
                  px={2}
                  spacing={2}
                >
                  <ProdCalen setDate={setMonthCalendar} users={users} />
                  <Stack spacing={0.5}>
                    <Typography pb={1}>Дни рождения в этом месяце:</Typography>
                    {Array.isArray(users) &&
                      users.length > 0 &&
                      users.map((user) => (
                        <Typography key={user?.source_id} variant="body2">
                          {dayjs(user.birth_date).format("DD.MM")} -{" "}
                          {user.display_name}
                        </Typography>
                      ))}
                    {Array.isArray(users) && users.length === 0 && (
                      <Typography variant="body2">Нет данных</Typography>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Card>
        <Card sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h5">Учет рабочего времени:</Typography>
                <Stack alignItems="center" direction="row">
                  <IconButton onClick={handlePrev}>
                    <ArrowCircleLeftIcon fontSize="large" />
                  </IconButton>
                  <ButtonGroup variant="outlined">
                    <Button
                      onClick={handleClickMonth}
                      sx={{ minWidth: "110px !important" }}
                    >
                      {dayjs(month).format("MMMM")}
                    </Button>
                    <Button
                      onClick={handleClickYear}
                      sx={{ minWidth: "70px !important" }}
                    >
                      {dayjs(year).format("YYYY")}
                    </Button>
                  </ButtonGroup>
                  <IconButton onClick={handleNext}>
                    <ArrowCircleRightIcon fontSize="large" />
                  </IconButton>
                  <Popover
                    anchorEl={anchorMonth}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    onClose={handleCloseMonth}
                    open={!!anchorMonth}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MonthCalendar
                        onChange={(v) => setMonth(v)}
                        value={month}
                      />
                    </LocalizationProvider>
                  </Popover>
                  <Popover
                    anchorEl={anchorYear}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    onClose={handleCloseYear}
                    open={!!anchorYear}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <YearCalendar onChange={(v) => setYear(v)} value={year} />
                    </LocalizationProvider>
                  </Popover>
                </Stack>
              </Stack>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <ReactApexChart
                    height={350}
                    options={options1}
                    series={series1}
                    type="line"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ReactApexChart
                    height={350}
                    options={options2}
                    series={series2}
                    type="line"
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h5">Табель рабочего времени:</Typography>
              <MaterialTable
                columns={tableColumns}
                data={timesheet}
                enableBottomToolbar={false}
                enableSorting={false}
                enableTopToolbar={false}
                initialState={{
                  density: "compact",
                }}
                muiTableBodyCellProps={{
                  sx: {
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    backgroundColor: "#ffffff",
                  },
                }}
                muiTableContainerProps={{
                  sx: {
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                  },
                }}
                state={{ isLoading: loadingTimesheet }}
              />
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
};

export default MainPage;
