import React, { useCallback, useEffect, useRef, useState } from "react";

import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Avatar, Button, Card, Grid, Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import AlertMessage from "../Alert/AlertMessage";
import BlockFormModal from "../BlockFormModal";
import LoadingSpinner from "../modals/loadingSpinner";
import CustomTextField from "../TextFields/CustomTextField";

const acceptFileType = ["image/jpeg", "image/png"];

const UserData = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [uploadedAvatarLabel, setUploadedAvatarLabel] = useState(null);

  const methods = useForm({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    setValue,
    control,
  } = methods;

  const {
    first_name,
    last_name,
    email,
    photo,
    phone,
    birth_date,
    country,
    street_address,
    work_schedule,
    communication_channels,
  } = useSelector((state) => state.authReducer);

  const handleReset = useCallback(() => {
    setValue("first_name", first_name);
    setValue("last_name", last_name);
    setValue("email", email);
    setValue("phone", phone);
    setValue("birth_date", birth_date);
    setValue("country", country);
    setValue("street_address", street_address);
    setValue("work_schedule", work_schedule);
    setValue("communication_channels", communication_channels);
  }, [
    birth_date,
    communication_channels,
    country,
    email,
    first_name,
    last_name,
    phone,
    setValue,
    street_address,
    work_schedule,
  ]);

  useEffect(() => {
    handleReset();
  }, [handleReset]);

  const { setErrorAlertMessage, updateUser } = useActions();

  const inputRef = useRef(null);

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (
      fileObj &&
      acceptFileType.indexOf(fileObj.type) !== -1 &&
      fileObj.size / 1024 ** 2 < 3.6
    ) {
      const FR = new FileReader();
      FR.addEventListener("load", function (evt) {
        setUploadedAvatar(evt.target.result);
      });
      FR.readAsDataURL(event.target.files[0]);
      setUploadedAvatarLabel(fileObj.name);
    } else {
      setErrorAlertMessage(t("src.pages.AccountPage.UserData.extension"));
    }
  };

  const cleanInput = () => {
    setUploadedAvatar(null);
    setUploadedAvatarLabel(null);
  };

  const loadData = useCallback(() => {
    try {
      const getUser = async () => {
        const response = await $authHost.get(`/users/me/`);
        return response.data;
      };
      getUser().then(
        ({
          display_name,
          first_name,
          last_name,
          email,
          phone,
          birth_date,
          work_schedule,
          country,
          photo,
          street_address,
          communication_channels,
          groups,
        }) => {
          updateUser({
            display_name,
            first_name,
            last_name,
            email,
            phone,
            birth_date,
            work_schedule,
            country,
            photo,
            street_address,
            communication_channels,
            groups,
          });
        },
      );
    } catch (e) {
      console.log(e);
    }
  }, [updateUser]);

  const onSubmit = async ({ email, photo, ...data }) => {
    setLoading(true);
    const newData = data;
    newData.display_name = `${data.first_name} ${data.last_name}`;
    if (uploadedAvatar !== null) {
      newData.photo = uploadedAvatar.split(",")[1];
    }
    try {
      await $authHost.put(`/users/me/update/`, newData);
      cleanInput();
      loadData();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ px: 2, py: 4, my: 2 }}>
      {loading && <LoadingSpinner />}
      <AlertMessage />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item md={12}>
              <Grid container>
                <Grid item md={4}>
                  <Stack alignItems="center">
                    <div className="containerImg">
                      <Avatar
                        alt="photoURL"
                        id="img"
                        src={uploadedAvatar || photo}
                        sx={{ width: 200, height: 200, mb: 2 }}
                      />
                      <input
                        id="avatar"
                        onChange={handleFileChange}
                        ref={inputRef}
                        style={{ display: "none" }}
                        type="file"
                      />
                      <div
                        className="overlay"
                        onClick={() => {
                          inputRef.current.click();
                        }}
                      >
                        <IconButton aria-label="delete" className="icon">
                          <DriveFolderUploadIcon fontSize="inherit" />
                        </IconButton>
                      </div>
                    </div>
                    {uploadedAvatarLabel && (
                      <Chip
                        label={uploadedAvatarLabel}
                        onDelete={cleanInput}
                        variant="outlined"
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item md={8}>
                  <CustomTextField
                    label="Имя"
                    name="first_name"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                    }}
                    size="small"
                    sx={{ width: "100%", mb: 3 }}
                  />
                  <CustomTextField
                    label="Фамилия"
                    name="last_name"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                    }}
                    size="small"
                    sx={{ width: "100%", mb: 3 }}
                  />
                  <CustomTextField
                    disabled
                    label="Email"
                    name="email"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                    }}
                    size="small"
                    sx={{ width: "100%", mb: 3 }}
                  />
                  <CustomTextField
                    label="Телефон"
                    name="phone"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                    }}
                    size="small"
                    sx={{ width: "100%", mb: 3 }}
                  />
                  <CustomTextField
                    label="Доп каналы связи"
                    multiline
                    name="communication_channels"
                    rows={3}
                    size="small"
                    sx={{ width: "100%", mb: 3 }}
                  />
                  <Controller
                    control={control}
                    name="birth_date"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          control={control}
                          format="DD/MM/YYYY"
                          label="Дата рождения"
                          onChange={(e) => {
                            onChange(e);
                          }}
                          slotProps={{
                            textField: {
                              error: !!error,
                              helperText: error?.message,
                              size: "small",
                              InputLabelProps: {
                                shrink: true,
                              },
                            },
                          }}
                          sx={{
                            width: "100%",
                            mb: 3,
                          }}
                          value={dayjs(value)}
                        />
                      </LocalizationProvider>
                    )}
                    rules={{
                      required: t("main.another.requiredField"),
                    }}
                  />
                  <CustomTextField
                    label="Страна"
                    name="country"
                    size="small"
                    sx={{ width: "100%", mb: 3 }}
                  />
                  <CustomTextField
                    label="Почтовый адрес"
                    multiline
                    name="street_address"
                    rows={3}
                    size="small"
                    sx={{ width: "100%", mb: 3 }}
                  />
                  <CustomTextField
                    label="Рабочий график"
                    multiline
                    name="work_schedule"
                    registerOptions={{
                      required: t("main.another.requiredField"),
                    }}
                    rows={3}
                    size="small"
                    sx={{ width: "100%", mb: 3 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button color="primary" type="submit" variant="contained">
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Card>
  );
};

export default UserData;
