import React, { useCallback, useEffect, useState } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BlockFormModal from "../../components/BlockFormModal";
import SkeletonInput from "../../components/SkeletonInput";
import VirtualizedAutocomplete from "../../components/VirtualizedAutocomplete";
import { currencies2 } from "../../constants";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const ProjectsAddForm = () => {
  const navigate = useNavigate();

  const { setClientsAll, setEmployees } = useActions();

  const { clientsAll } = useSelector((state) => state.clientsReducer);
  const { employees } = useSelector((state) => state.employeesReducer);

  const loadClients = useCallback(() => {
    setLoading(true);

    try {
      const newChannelList = async () => {
        const response = await $authHost.get("/clients/all/dropdown/");
        return response.data;
      };
      newChannelList().then((data) => {
        setClientsAll(data);
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setClientsAll]);

  const loadUsers = useCallback(() => {
    setLoading(true);

    try {
      const newChannelList = async () => {
        const response = await $authHost.get("/users/all/");
        return response.data.items;
      };
      newChannelList().then((data) => {
        setEmployees(data);
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setEmployees]);

  useEffect(() => {
    loadClients();
    loadUsers();
  }, [loadClients, loadUsers]);

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      team: [],
      start_date: null,
      end_date: null,
      actual_end_date: null,
      actual_start_date: null,
    },
  });

  const {
    register,
    getValues,
    setValue,
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    reset,
    setError,
  } = methods;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onSubmit = async (data) => {
    setLoading(true);

    const {
      client,
      code,
      currency,
      start_date,
      end_date,
      actual_start_date,
      actual_end_date,
      manager,
      name,
      notes,
      status,
      team,
      currency_budget,
      commercial_hours_budget,
      internal_hours_budget,
    } = data;

    const newData = {
      code,
      name,
      notes,
      status,
      currency_budget: currency_budget === "" ? null : currency_budget,
      commercial_hours_budget:
        commercial_hours_budget === "" ? null : commercial_hours_budget,
      internal_hours_budget:
        internal_hours_budget === "" ? null : internal_hours_budget,
      currency: currency?.value,
      client_id: client?.id,
      start_date: start_date
        ? dayjs(start_date).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      end_date: end_date ? dayjs(end_date).format("YYYY-MM-DDTHH:mm:ss") : null,
      actual_start_date: actual_start_date
        ? dayjs(actual_start_date).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      actual_end_date: actual_end_date
        ? dayjs(actual_end_date).format("YYYY-MM-DDTHH:mm:ss")
        : null,
      manager_id: manager?.source_id,
      team:
        Array.isArray(team) && team.length > 0
          ? team.map((user) => user?.source_id)
          : [],
    };

    try {
      await $authHost.post("/projects/add/", newData);
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/projects");
    }
  }, [isSubmitSuccessful, navigate]);

  // const clickupList = [
  //   { id: 1, name: 'name 1' },
  //   { id: 2, name: 'name 2' },
  //   { id: 3, name: 'name 3' },
  //   { id: 4, name: 'name 4' },
  //   { id: 5, name: 'name 5' },
  // ];

  const clickupList = ["name 1", "name 2", "name 3", "name 4", "name 5"];

  // const filterColors = async (inputValue) => {
  //   var array = [{value: "22", label: "vdfvdf"}, {value: "33", label: "bdgbfgbf"}];
  //   // const fetched = await $authHost.get(`/reference?search=${inputValue}`).then(res => {
  //   //   const references = res.data;
  //   //   references.map((reference, index)=> {
  //   //     array.push({ value: reference, label: reference })
  //   //   })
  //   // })
  //   return array;
  // };
  //
  //
  // async function promiseOptions(inputValue){
  //   return await filterColors(inputValue);
  // }

  // const HandleReset = () => {
  //   let defaultValues = {};
  //   defaultValues.name = [];
  //   defaultValues.code = [];
  //   defaultValues.notes = [];
  //   defaultValues.start_date = [];
  //   defaultValues.end_date = [];
  //   defaultValues.currency_budget = [];
  //   defaultValues.commercial_hours_budget = [];
  //   defaultValues.internal_hours_budget = [];
  //   defaultValues.team = [];
  //   defaultValues.manager = [];
  //   defaultValues.client = [];
  //   defaultValues.teams = [];
  //   defaultValues.clickup = [];
  //   defaultValues.azure_dev_ops_space = [];
  //   defaultValues.currency = [];
  //   // defaultValues.currency = [];
  //   reset({ ...defaultValues });
  //
  //
  //
  // }

  const handleBack = () => {
    navigate("/projects");
  };

  const manager = useWatch({ control, name: "manager" });

  useEffect(() => {
    const team = getValues("team");
    if (manager && Array.isArray(team)) {
      const managerInTeam = team.find((v) => v?.id === manager?.id);
      if (!managerInTeam) {
        setValue("team", [manager, ...team]);
      }
    }
  }, [getValues, manager, setValue]);

  const [currencyAdornmentLabel, setCurrencyAdornmentLabel] = useState("$");
  const [projectCodeInput, setProjectCodeInput] = useState("");
  const currency = useWatch({ control, name: "currency" });
  const client = useWatch({ control, name: "client" });
  const projectCode = useWatch({ control, name: "code" });

  useEffect(() => {
    currency?.label && setCurrencyAdornmentLabel(currency.label);
  }, [currency]);

  useEffect(() => {
    if (!projectCode && client?.code) {
      setValue("code", `${client.code}_`);
      setProjectCodeInput(`${client.code}_`);
    }
  }, [client, projectCode, setValue]);

  return (
    <Container sx={{ pb: 2 }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mb={0}
        sx={{ height: "70px" }}
      >
        <Typography gutterBottom variant="h4">
          Новый проект
        </Typography>
      </Stack>
      <Box sx={{ width: "100%" }}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid columnSpacing={4} container rowSpacing={2}>
              <Grid item md={6} xs={12}>
                <Typography
                  sx={{
                    mb: 2,
                  }}
                  variant="subtitle1"
                >
                  Общие сведения
                </Typography>
                <Controller
                  control={control}
                  name="code"
                  render={({
                    field: { onChange, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        {...field}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={!!error}
                        fullWidth
                        helperText={error?.message}
                        label="Код проекта *"
                        onChange={(e) => {
                          onChange(e);
                          setProjectCodeInput(e.target.value);
                        }}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                        value={projectCodeInput}
                      />
                    );
                  }}
                  rules={{
                    required: "Обязательное поле",
                  }}
                />
                <TextField
                  label="Наименование проекта *"
                  {...register("name", {
                    required: "Обязательное поле",
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  size="small"
                  sx={{
                    mb: 2,
                  }}
                />
                <VirtualizedAutocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  label="Клиент"
                  loading={loading}
                  name="client"
                  options={clientsAll}
                  required
                />
                <TextField
                  label="Статус проекта *"
                  {...register("status", {
                    required: "Обязательное поле",
                  })}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors?.status}
                  fullWidth
                  helperText={errors?.status?.message}
                  size="small"
                  sx={{
                    mb: 2,
                  }}
                />
                <TextField
                  label="Описание"
                  {...register("notes")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors?.notes}
                  fullWidth
                  helperText={errors?.notes?.message}
                  multiline={true}
                  rows={4}
                  size="small"
                  sx={{
                    mb: 2,
                  }}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  sx={{
                    mb: 2,
                  }}
                  variant="subtitle1"
                >
                  Интеграция
                </Typography>
                <Controller
                  control={control}
                  name="clickup"
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        onInputChange={(_, data) => {
                          if (data) field.onChange(data);
                        }}
                        options={clickupList}
                        renderInput={(params) => (
                          <TextField
                            label="ClickUp"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    );
                  }}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                />
                <Controller
                  control={control}
                  name="teams"
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        onInputChange={(_, data) => {
                          if (data) field.onChange(data);
                        }}
                        options={clickupList}
                        renderInput={(params) => (
                          <TextField
                            label="Teams"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    );
                  }}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                />
                <Controller
                  control={control}
                  name="azure_dev_ops_space"
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => field.onChange(value)}
                        onInputChange={(_, data) => {
                          if (data) field.onChange(data);
                        }}
                        options={clickupList}
                        renderInput={(params) => (
                          <TextField
                            label="Azure"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                            inputRef={ref}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    );
                  }}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  sx={{
                    mb: 2,
                  }}
                  variant="subtitle1"
                >
                  Свойства
                </Typography>
                <Grid columnSpacing={2} container>
                  <Grid item xs={6}>
                    <SkeletonInput loading={loading}>
                      <Controller
                        control={control}
                        name="actual_start_date"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              control={control}
                              format="DD.MM.YYYY"
                              label="Старт проекта"
                              onChange={(e) => {
                                onChange(e);
                              }}
                              slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error?.message,
                                  size: "small",
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                },
                              }}
                              sx={{
                                width: "100%",
                                mb: 2,
                              }}
                              value={dayjs(value)}
                            />
                          </LocalizationProvider>
                        )}
                        rules={
                          {
                            // required: 'Обязательное поле',
                          }
                        }
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <Controller
                        control={control}
                        name="actual_end_date"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              control={control}
                              format="DD.MM.YYYY"
                              label="Дата завершения"
                              onChange={(e) => {
                                onChange(e);
                              }}
                              slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error?.message,
                                  size: "small",
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                },
                              }}
                              sx={{
                                width: "100%",
                                mb: 2,
                              }}
                              value={dayjs(value)}
                            />
                          </LocalizationProvider>
                        )}
                        rules={
                          {
                            // required: 'Обязательное поле',
                          }
                        }
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <Controller
                        control={control}
                        name="currency"
                        render={({
                          field: { onChange, ...field },
                          fieldState: { error },
                        }) => {
                          return (
                            <Autocomplete
                              {...field}
                              getOptionLabel={(option) => option}
                              onChange={(e, value) => onChange(value)}
                              options={currencies2}
                              renderInput={(params) => (
                                <TextField
                                  label="Валюта проекта"
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={!!error}
                                  helperText={error?.message}
                                />
                              )}
                              size="small"
                              sx={{
                                mb: 2,
                              }}
                            />
                          );
                        }}
                        rules={
                          {
                            // required: 'Обязательное поле',
                          }
                        }
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Бюджет в валюте"
                        {...register("currency_budget", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencyAdornmentLabel}
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors?.currency_budget}
                        fullWidth
                        helperText={errors?.currency_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Бюджет в ком. часах"
                        {...register("commercial_hours_budget", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">h</InputAdornment>
                          ),
                        }}
                        error={!!errors?.commercial_hours_budget}
                        fullWidth
                        helperText={errors?.commercial_hours_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Бюджет во вн. часах"
                        {...register("internal_hours_budget", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">h</InputAdornment>
                          ),
                        }}
                        error={!!errors?.internal_hours_budget}
                        fullWidth
                        helperText={errors?.internal_hours_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                  </Grid>
                  <Grid item xs={6}>
                    <SkeletonInput loading={loading}>
                      <Controller
                        control={control}
                        name="start_date"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              control={control}
                              format="DD.MM.YYYY"
                              label="Старт План"
                              onChange={(e) => {
                                onChange(e);
                              }}
                              slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error?.message,
                                  size: "small",
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                },
                              }}
                              sx={{
                                width: "100%",
                                mb: 2,
                              }}
                              value={dayjs(value)}
                            />
                          </LocalizationProvider>
                        )}
                        rules={
                          {
                            // required: 'Обязательное поле',
                          }
                        }
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <Controller
                        control={control}
                        name="end_date"
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              control={control}
                              format="DD.MM.YYYY"
                              label="Завершение План"
                              onChange={(e) => {
                                onChange(e);
                              }}
                              slotProps={{
                                textField: {
                                  error: !!error,
                                  helperText: error?.message,
                                  size: "small",
                                  InputLabelProps: {
                                    shrink: true,
                                  },
                                },
                              }}
                              sx={{
                                width: "100%",
                                mb: 9,
                              }}
                              value={dayjs(value)}
                            />
                          </LocalizationProvider>
                        )}
                        rules={
                          {
                            // required: 'Обязательное поле',
                          }
                        }
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Нач. бюджет в валюте"
                        {...register("currency_budget_start", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencyAdornmentLabel}
                            </InputAdornment>
                          ),
                        }}
                        error={!!errors?.currency_budget}
                        fullWidth
                        helperText={errors?.currency_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Нач. бюджет в ком. часах"
                        {...register("commercial_hours_budget_start", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">h</InputAdornment>
                          ),
                        }}
                        error={!!errors?.commercial_hours_budget}
                        fullWidth
                        helperText={errors?.commercial_hours_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                    <SkeletonInput loading={loading}>
                      <TextField
                        label="Нач. бюджет во вн. часах"
                        {...register("internal_hours_budget_start", {
                          // required: 'Обязательное поле',
                          pattern: {
                            value: /^\d*$/,
                            message: "Некорректный ввод",
                          },
                        })}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">h</InputAdornment>
                          ),
                        }}
                        error={!!errors?.internal_hours_budget}
                        fullWidth
                        helperText={errors?.internal_hours_budget?.message}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    </SkeletonInput>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  sx={{
                    mb: 2,
                  }}
                  variant="subtitle1"
                >
                  Команда проекта
                </Typography>
                <Controller
                  control={control}
                  name="manager"
                  render={({
                    field: { onChange, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option.display_name}
                        onChange={(e, value) => onChange(value)}
                        options={employees}
                        renderInput={(params) => (
                          <TextField
                            label="Менеджер проекта *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    );
                  }}
                  rules={{
                    required: "Обязательное поле",
                  }}
                />
                <Controller
                  control={control}
                  name="team"
                  render={({
                    field: { onChange, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option.display_name}
                        isOptionEqualToValue={(option, value) =>
                          option?.source_id === value?.source_id
                        }
                        multiple
                        onChange={(e, value) => onChange(value)}
                        options={employees}
                        renderInput={(params) => (
                          <TextField
                            label="Команда *"
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2,
                        }}
                      />
                    );
                  }}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      disabled={loading}
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                    <Button
                      color="primary"
                      onClick={handleBack}
                      sx={{ ml: 1 }}
                      variant="text"
                    >
                      Назад
                    </Button>
                  </Grid>
                  <Grid item>
                    {/*<Button*/}
                    {/*  variant="text"*/}
                    {/*  color="primary"*/}
                    {/*  sx={{ ml: 1 }}*/}
                    {/*  onClick={HandleReset}*/}
                    {/*>*/}
                    {/*  Сбросить*/}
                    {/*</Button>*/}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Container>
  );
};

export default ProjectsAddForm;
