import React, { useState } from "react";

import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DynamicTabs = ({ components, orientation }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          onChange={handleChange}
          orientation={orientation}
          value={value}
          variant="scrollable"
        >
          {components.map((component, index) => (
            <Tab
              icon={component.icon}
              iconPosition={component.iconPosition}
              key={index}
              label={component.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {components.map((component, index) => (
        <TabPanel index={index} key={index} value={value}>
          {component.component}
        </TabPanel>
      ))}
    </Box>
  );
};

export default DynamicTabs;
