import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import LanguageSwitchMobile from "./LanguageSwitchMobile";
import ThemeSwitch from "../../components/ThemeSwitch";
import { desiredLocations } from "../../constants";
import { useAuth } from "../../hook/useAuth";
import useResponsive from "../../hook/useResponsive";
import { StyledChip, StyledSatus } from "../../theme/standarts_styles";

export default function AccountPopover() {
  const { t } = useTranslation();
  const MENU_OPTIONS = [
    {
      label: t("src.layouts.dashboard.header.AccountPopover.munuOption1"),
      icon: "eva:settings-2-fill",
      path: "/account",
    },
  ];
  const [open, setOpen] = useState(null);
  const isDesktop = useResponsive("up", "lg");
  const { signOut } = useAuth();
  const location = useLocation();
  const currentLocation = desiredLocations.includes(location.pathname);

  const { sseChannelError } = useSelector((state) => state.serviceReducer);

  const { display_name, email, photo } = useSelector(
    (state) => state.authReducer,
  );

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    signOut();
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="text">
        <Stack alignItems="center" direction="row" spacing={1}>
          {/*<Badge*/}
          {/*  color={sseChannelError ? 'error' : 'success'}*/}
          {/*  variant='dot'*/}
          {/*  overlap='circular'*/}
          {/*  badgeContent=' '*/}
          {/*  sx={{mr: isDesktop ? 1 : 0}}*/}
          {/*>*/}
          <Avatar
            alt="photoURL"
            src={photo}
            sx={{
              width: "30px",
              height: "30px",
              mr: isDesktop ? 1 : 0,
            }}
          />
          {/*</Badge>*/}
          {isDesktop && (
            <Box>
              <Typography
                sx={{
                  color: "layout.element",
                  textAlign: "start",
                }}
                variant="subtitle1"
              >
                {display_name}
              </Typography>
            </Box>
          )}
          <KeyboardArrowDownIcon
            fontSize="medium"
            sx={{
              color: "layout.element",
            }}
          />
        </Stack>
      </Button>

      <Popover
        PaperProps={{
          sx: {
            zIndex: 5001,
            backgroundColor: "background.neutral",
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
        anchorEl={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        open={Boolean(open)}
        sx={{ zIndex: "10000" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography noWrap variant="subtitle2">
            {display_name}
          </Typography>
          <Typography noWrap sx={{ color: "text.secondary" }} variant="body2">
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed", color: "divider" }} />

        {/*more navigation*/}
        {!currentLocation && (
          <>
            <Stack sx={{ p: 1 }}>
              {MENU_OPTIONS.map((option, index) => (
                <NavLink
                  key={index}
                  onClick={handleClose}
                  style={{
                    fontStyle: "none",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  to={option.path}
                >
                  <MenuItem key={option.label}>{option.label}</MenuItem>
                </NavLink>
              ))}
            </Stack>
            <Divider sx={{ borderStyle: "dashed", color: "divider" }} />
          </>
        )}
        {/*<MenuItem sx={{m: 1}}>*/}
        {/*  <ThemeSwitch/>*/}
        {/*</MenuItem>*/}
        {/*<Divider sx={{borderStyle: 'dashed', color: 'divider'}}/>*/}

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t("src.layouts.dashboard.header.AccountPopover.exit")}
        </MenuItem>
      </Popover>
    </>
  );
}
