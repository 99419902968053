export const getSortingString = (sorting) => {
  let sortStr = "";

  if (Array.isArray(sorting) && sorting.length > 0) {
    sortStr = sorting
      .map((item) => (item.desc ? `-${item.id}` : item.id))
      .join(",");
  }

  return sortStr;
};
