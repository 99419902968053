import { Skeleton } from "@mui/material";

const SkeletonInput = ({
  loading,
  height = 50,
  width = "100%",
  sx,
  children,
}) => {
  if (loading) {
    return <Skeleton height={height} sx={sx} width={width} />;
  }
  return children;
};

export default SkeletonInput;
