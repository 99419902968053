import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomViewField from "../../components/CustomViewField";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const ClientsFormView = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const { updateFullSelectedClient } = useActions();

  const { selectedClient } = useSelector((state) => state.clientsReducer);

  const loadData = useCallback(async () => {
    try {
      const response = await $authHost.get(`/clients/${clientId}/`);
      updateFullSelectedClient(response.data);
    } catch (e) {
      console.log(e);
    }
  }, [clientId, updateFullSelectedClient]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleBack = () => {
    navigate("/clients");
  };

  const handleEdit = () => {
    navigate(`/clients/edit/${clientId}`);
  };

  return (
    <Box sx={{ flexDirection: "column", px: 0, py: 4 }}>
      {loading && <LoadingSpinner />}
      <CustomViewField
        label="Наименование"
        loading={loading}
        text={selectedClient?.name}
      />
      <CustomViewField
        label="Полное наименование"
        loading={loading}
        text={selectedClient?.full_name}
      />
      <CustomViewField
        label="Код клиента"
        loading={loading}
        text={selectedClient?.code}
      />
      <CustomViewField
        label="Адрес"
        loading={loading}
        text={selectedClient?.address}
      />
      <CustomViewField
        label="Веб-сайт"
        loading={loading}
        path={selectedClient?.web_site}
        text={selectedClient?.web_site}
        type="link"
      />
      <CustomViewField
        label="Email"
        loading={loading}
        text={selectedClient?.email}
      />
      <CustomViewField
        copyButton={true}
        label="Реквизиты"
        loading={loading}
        text={selectedClient?.details}
      />
      <CustomViewField
        label="Страна"
        loading={loading}
        text={selectedClient?.country}
      />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item>
          <Button onClick={handleBack} variant="contained">
            Назад к списку
          </Button>
          <Button
            color="primary"
            onClick={handleEdit}
            sx={{ ml: 1 }}
            variant="text"
          >
            Редактировать
          </Button>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ClientsFormView;
