import React from "react";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { getLocalizedForDropdownValues } from "../../../utils/getLocalizedForDropdownValues";
import CustomViewField from "../../CustomViewField";
import CustomMultiAutocompleteForObjectLocalized from "../../TextFields/CustomMultiAutocompleteForObjectLocalized";
import CustomMultiTextFieldAutocompleteForObject from "../../TextFields/CustomMultiTextFieldAutocompleteForObject";
import CustomTextField from "../../TextFields/CustomTextField";

const LeaveApplicationTopFields = ({
  statusOptions,
  loading,
  selectedClient,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomViewField
        label="Отправитель"
        loading={loading}
        text={
          selectedClient?.user?.display_name ||
          (selectedClient &&
            `${selectedClient?.user?.first_name} ${selectedClient?.user?.last_name}`) ||
          null
        }
      />
      <CustomViewField
        label="Дата создания"
        loading={loading}
        text={dayjs(selectedClient.created_at).format("DD.MM.YYYY") || ""}
      />
      <CustomViewField
        label="Отдел"
        loading={loading}
        text={selectedClient?.department?.name || null}
      />
      <CustomMultiAutocompleteForObjectLocalized
        // multiple={true}
        label="Статус"
        name="status"
        onChangeValue={(e) => {}}
        options={getLocalizedForDropdownValues(statusOptions, "status")}
        rules={{
          required: t("main.another.requiredField"),
        }}
        size="small"
        sx={{ width: "100%", fontSize: "12px", mb: 3 }}
      />
      <CustomTextField
        label="Комментарий"
        multiline={true}
        name="comment"
        registerOptions={{
          required: t("main.another.requiredField"),
        }}
        rows={3}
        size="small"
        sx={{ width: "100%", fontSize: "12px", mb: 3 }}
      />
    </>
  );
};

export default LeaveApplicationTopFields;
