import React, { useCallback, useEffect, useMemo, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Card,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { CURRENCIES } from "../../constants";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import BlockFormModal from "../BlockFormModal";
import SkeletonInput from "../SkeletonInput";

const BudgetsEdit = ({ handleClose, loadDataBudgets, budgetId }) => {
  const [loading, setLoading] = useState(false);
  const [currencyAdornmentLabel, setCurrencyAdornmentLabel] = useState("$");
  const isMobile = useResponsive("down", "md");

  const { setBudget } = useActions();

  const { budget } = useSelector((state) => state.budgetsReducer);
  const { project } = useSelector((state) => state.projectsReducer);

  const loadBudget = useCallback(() => {
    setLoading(true);
    try {
      const getBudget = async () => {
        const response = await $authHost.get(`/budgets/${budgetId}/`);
        setBudget(response.data);
        setLoading(false);
      };
      getBudget();
    } catch (e) {
      console.log(e);
    }
  }, [budgetId, setBudget]);

  useEffect(() => {
    budgetId && loadBudget();
  }, [budgetId, loadBudget]);

  useEffect(() => {
    if (project?.currency) {
      const currency = CURRENCIES.find((v) => v.value === project?.currency);
      currency && setCurrencyAdornmentLabel(currency.label);
    }
  }, [project]);

  const methods = useForm({
    mode: "onBlur",
    defaultValues: useMemo(() => {
      return budget;
    }, [budget]),
  });

  const {
    register,
    formState: { errors, isDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    reset,
  } = methods;

  useEffect(() => {
    reset(budget);
  }, [budget, reset]);

  const onSubmit = async (data) => {
    setLoading(true);

    const { currency_amount, date, hours_amount, justification, name, type } =
      data;

    const newData = {
      currency_amount,
      hours_amount,
      justification,
      type,
      // name,
      // date: dayjs(date).format('YYYY-MM-DDTHH:mm:ss'),
    };

    try {
      const response = await $authHost.put(
        `/budgets/${budget?.id}/update/`,
        newData,
      );
      setLoading(false);
      handleClose();
      loadDataBudgets();
    } catch (e) {
      setLoading(false);
    }
  };

  const typesList = ["Внутренний", "Коммерческий", "Риски", "НЗ"];

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Card sx={{ p: 3, mb: 2 }}>
          {isMobile && (
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              sx={{ mb: 3 }}
            >
              <Grid item>
                <Typography color="text.secondary" variant="subtitle1">
                  Редактировать
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose} sx={{ p: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              alignItems="flex-start"
              container
              direction="column"
              justifyContent="space-between"
            >
              <SkeletonInput loading={loading}>
                <Controller
                  control={control}
                  name="date"
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        control={control}
                        disabled
                        label="Дата"
                        onChange={(e) => {
                          onChange(e);
                        }}
                        slotProps={{
                          textField: {
                            error: !!error,
                            helperText: error?.message,
                            size: "small",
                          },
                        }}
                        sx={{
                          width: "100%",
                          mb: 2,
                        }}
                        value={dayjs(value)}
                      />
                    </LocalizationProvider>
                  )}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label="Имя бюджета"
                  {...register("name", {
                    // required: 'Обязательное поле',
                  })}
                  disabled
                  error={!!errors?.name}
                  fullWidth
                  helperText={errors?.name?.message}
                  size="small"
                  sx={{
                    mb: 2,
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <Controller
                  control={control}
                  name="type"
                  render={({
                    field: { onChange, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        {...field}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => onChange(value)}
                        options={typesList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error}
                            helperText={error?.message}
                            label="Вид"
                          />
                        )}
                        size="small"
                        sx={{
                          mb: 2,
                          width: "100%",
                        }}
                      />
                    );
                  }}
                  rules={
                    {
                      // required: 'Обязательное поле',
                    }
                  }
                />
              </SkeletonInput>
              <SkeletonInput height={150} loading={loading}>
                <TextField
                  label="Обоснование"
                  {...register("justification")}
                  error={!!errors?.notes}
                  fullWidth
                  helperText={errors?.notes?.message}
                  multiline
                  rows={4}
                  size="small"
                  sx={{
                    mb: 2,
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label="Сумма в валюте"
                  {...register("currency_amount", {
                    // required: 'Обязательное поле',
                    pattern: {
                      value: /^\d*$/,
                      message: "Некорректный ввод",
                    },
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencyAdornmentLabel}
                      </InputAdornment>
                    ),
                  }}
                  error={!!errors?.currency_amount}
                  fullWidth
                  helperText={errors?.currency_amount?.message}
                  size="small"
                  sx={{
                    mb: 2,
                  }}
                />
              </SkeletonInput>
              <SkeletonInput loading={loading}>
                <TextField
                  label="Сумма в коммерческих часах"
                  {...register("hours_amount", {
                    // required: 'Обязательное поле',
                    pattern: {
                      value: /^\d*$/,
                      message: "Некорректный ввод",
                    },
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">h</InputAdornment>
                    ),
                  }}
                  error={!!errors?.hours_amount}
                  fullWidth
                  helperText={errors?.hours_amount?.message}
                  size="small"
                  sx={{
                    mb: 2,
                  }}
                />
              </SkeletonInput>
            </Grid>
            <Grid item sx={{ width: "100%", mb: 2 }}>
              {isMobile ? (
                <Stack
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                  spacing={2}
                  sx={{ width: "100%", mt: 3 }}
                >
                  <Button
                    disabled={loading}
                    sx={{ width: "100%" }}
                    type="submit"
                    variant="contained"
                  >
                    Сохранить
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={() => reset()}
                    sx={{ width: "100%" }}
                    variant="outlined"
                  >
                    Сбросить
                  </Button>
                </Stack>
              ) : (
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      disabled={loading}
                      sx={{ mr: 2 }}
                      type="submit"
                      variant="contained"
                    >
                      Сохранить
                    </Button>
                    <Button onClick={handleClose} variant="text">
                      Закрыть
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={loading}
                      onClick={() => reset()}
                      variant="text"
                    >
                      Сбросить
                    </Button>
                  </Grid>
                </Stack>
              )}
            </Grid>
          </form>
        </Card>
      </Grid>
      <BlockFormModal when={isDirty && !isSubmitSuccessful} />
    </Grid>
  );
};

export default BudgetsEdit;
