import React, { useEffect } from "react";

import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { useSelector } from "react-redux";

import { useActions } from "../../hook/useActions";

const AlertMessage = () => {
  const {
    resetSuccessAlertMessage,
    resetErrorAlertMessage,
    resetInfoAlertMessage,
    resetAllAlertMessage,
  } = useActions();

  const { errorAlertMessage, successAlertMessage, infoAlertMessage } =
    useSelector((state) => state.serviceReducer);

  const alertTypes = [
    {
      type: "success",
      message: successAlertMessage,
      reset: resetSuccessAlertMessage,
    },
    {
      type: "warning",
      message: errorAlertMessage,
      reset: resetErrorAlertMessage,
    },
    { type: "info", message: infoAlertMessage, reset: resetInfoAlertMessage },
  ];

  useEffect(() => {
    return () => {
      resetAllAlertMessage();
    };
  }, [resetAllAlertMessage]);

  return (
    <>
      <Collapse in={alertTypes.some((alert) => alert.message)}>
        {alertTypes.map(
          (alert) =>
            alert.message && (
              <Alert
                key={alert.type}
                onClose={() => alert.reset()}
                severity={alert.type}
                sx={{ mb: 2 }}
              >
                {alert.message}
              </Alert>
            ),
        )}
      </Collapse>
    </>
  );
};

export default React.memo(AlertMessage);
