import React, { useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Card,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Stack,
  Avatar,
  Tooltip,
  Grid,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { filter } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import DepartmentsForm from "../../pages/forms/DepartmentsForm";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
import { ButtonStack, RowOptionsStack } from "../../theme/standarts_styles";
import { getSortingString } from "../../utils/getSortingString";
import { stringAvatar } from "../../utils/textAvatar";
import BlockFormModal from "../BlockFormModal";
import AddEditFormButtonGroup from "../forms/AddEditFormButtonGroup";
import BaseAddEditForm from "../forms/BaseAddEditForm";
import ContactsForm from "../forms/ContactsForm";
import Iconify from "../iconify";
import MaterialTable from "../MaterialTable";
import MaterialTablePagination from "../MaterialTablePagination";
import LoadingSpinner from "../modals/loadingSpinner";
import { TableCellsWrap } from "../TableCellsWrap";

const Departments = () => {
  const tableId = "departments";
  const tableStates = useSelector((state) => state.tableStatesReducer);
  const isMobile = useResponsive("down", "md");

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const { roles } = useSelector((state) => state.authReducer);

  const {
    setDepartments,
    setSelectedDepartment,
    setSelectedManager,
    setValidationErrorManager,
    setValidationManager,
  } = useActions();

  const { departments, selectedDepartment, selectedManager } = useSelector(
    (state) => state.departmentsReducer,
  );

  const handleAddClient = (event) => {
    setSelectedRow(null);
    setSelectedDepartment(null);
    setSelectedManager(null);
    setOpenForm((prevState) => {
      return prevState === "Add" ? "" : "Add";
    });
  };

  function handleEditClient(row) {
    setSelectedRow(row);
    setSelectedDepartment(row);
    setOpenForm("Update");
  }

  async function handleDeleteRow(id) {
    setLoading(true);
    try {
      await $authHost.delete(`/departments/${id}/delete/`).then((response) => {
        loadData();
      });
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  }

  const tableColumns = [
    {
      accessorKey: "name",
      header: "Название",
      accessorFn: (row) => row.name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "manager",
      header: "Менеджер",
      accessorFn: (row) => row.manager?.display_name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title="Подтвердить">
                <IconButton
                  color="inherit"
                  onClick={() => handleDeleteRow(row.original.id)}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Отмена">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(null)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <RowOptionsStack>
            {roles?.some((role) => role === "ERP_Admins") && (
              <>
                <Tooltip title="Редактировать">
                  <IconButton
                    color="inherit"
                    onClick={() => handleEditClient(row.original)}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Удалить">
                  <IconButton
                    color="inherit"
                    onClick={() => setConfirmationId(row.original.id)}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </RowOptionsStack>
        );
      },
    },
  ];

  const UpdateAction = async (data) => {
    try {
      const updateContact = async () => {
        return await $authHost.put(
          `/departments/${selectedDepartment.id}/update/`,
          {
            manager_id: data.manager.source_id,
            name: data.name,
          },
        );
      };

      updateContact().then(() => {
        setOpenForm("");
        loadData();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const AddAction = async (data) => {
    try {
      const newPerson = async () => {
        const response = await $authHost.post(`/departments/add/`, {
          name: data.name,
          manager_id: data.manager.source_id,
        });
        return response.data;
      };
      newPerson().then(() => {
        setOpenForm("");
        loadData();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const loadData = useCallback(
    async (search, index, size, sorting) => {
      setLoading(true);

      try {
        const sortStr = getSortingString(sorting);

        const response = await $authHost.get(`/departments/all/`, {
          params: {
            page: index + 1,
            size: size,
            ...(search && { search: search }),
            ...(sortStr && { sort_by: sortStr }),
          },
        });

        const data = response.data.items;
        setDepartments(data);
        setRowCount(response.data?.total);
        setPageCount(response.data?.pages);
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [setDepartments],
  );

  useEffect(() => {
    loadData(searchText, pagination.pageIndex, pagination.pageSize, sorting);
  }, [
    loadData,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    sorting,
  ]);

  useEffect(() => {
    if (!openForm && selectedDepartment) {
      setSelectedDepartment(null);
      setSelectedManager(null);
    }
    if (openForm === "") {
      setSelectedManager(null);
    }
  }, [openForm, selectedDepartment, setSelectedDepartment, setSelectedManager]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  return (
    <>
      <Box pt={roles?.some((role) => role === "ERP_Admins") ? 0 : 2}>
        {roles?.some((role) => role === "ERP_Admins") && (
          <ButtonStack>
            <Button
              aria-haspopup="true"
              disabled={!!selectedDepartment}
              onClick={handleAddClient}
              size={isMobile ? "small" : "medium"}
              startIcon={<Iconify icon="eva:plus-fill" />}
              sx={{ mr: 0 }}
              variant="contained"
            >
              Добавить отдел
            </Button>
          </ButtonStack>
        )}
        {openForm && openForm !== "View" && (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Card sx={{ p: 3, mb: 2 }}>
                <BaseAddEditForm
                  AddAction={AddAction}
                  UpdateAction={UpdateAction}
                  action={openForm}
                  handleClose={() => setOpenForm("")}
                  resetValue={{
                    name: "",
                    manager: "",
                  }}
                  selectedRow={selectedRow}
                  setConfirmation={setConfirmation}
                  setLoading={setLoading}
                >
                  <DepartmentsForm
                    action={openForm}
                    selectedRow={selectedRow}
                  />
                  <AddEditFormButtonGroup
                    action={openForm}
                    confirmation={confirmation}
                    handleClose={() => setOpenForm("")}
                  />
                </BaseAddEditForm>
              </Card>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box>
        <Card sx={{ pt: 1 }}>
          <MaterialTablePagination
            columns={tableColumns}
            data={departments}
            id={tableId}
            loading={loading}
            onGlobalFilterChange={onGlobalFilterChange}
            onSortingChange={setSorting}
            pageCount={pageCount}
            pagination={pagination}
            rowCount={rowCount}
            search={searchText}
            setPagination={setPagination}
            sorting={sorting}
          />
        </Card>
      </Box>
      <BlockFormModal
        title="Вы не завершили действие внутри таблицы"
        when={!!confirmationId}
      />
    </>
  );
};

export default Departments;
