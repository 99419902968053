import React, { useCallback, useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import CustomViewField from "../components/CustomViewField";
import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";
import { TitleStack } from "../theme/standarts_styles";

const EmployeePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { employeeId } = useParams();
  const isMobile = useResponsive("down", "md");

  const { selectedEmployee } = useSelector((state) => state.employeesReducer);

  const { roles } = useSelector((state) => state.authReducer);

  const {
    setEmployees,
    setSelectedEmployee,
    setDataSelectedEmployee,
    setSelectedDepartment,
  } = useActions();

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get(`/users/${employeeId}/`);
        return response.data;
      };
      newChannelList().then((data) => {
        setDataSelectedEmployee(data);
      });
    } catch (e) {
      console.log(e);
    }
  }, [employeeId, setDataSelectedEmployee]);

  const handleBack = () => {
    navigate("/org-structure/employees");
  };

  const handleEdit = () => {
    navigate(`/org-structure/employees/edit/${selectedEmployee.source_id}`);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Container sx={{ pb: 0 }}>
      <TitleStack>
        <Typography gutterBottom variant="h4">
          Управление {selectedEmployee?.display_name}
        </Typography>
      </TitleStack>
      <Card>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start"
          sx={{ p: isMobile ? 2 : 5 }}
        >
          {isMobile && (
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              sx={{ mb: 3 }}
            >
              <Grid item>
                <Typography color="text.secondary" variant="subtitle1">
                  Просмотр
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleBack} sx={{ p: 0 }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <Grid items ld={4} md={12} sm={12} xl={4} xs={12}>
            <Avatar
              alt="photoURL"
              id="img"
              src={selectedEmployee?.photo}
              sx={{ width: 250, height: 250, mb: 4 }}
            />
            <Typography
              sx={{
                mb: 2,
              }}
              variant="subtitle1"
            >
              Орг. структура
            </Typography>
            <CustomViewField
              label="Отдел"
              loading={false}
              text={selectedEmployee?.department?.name}
            />
            <CustomViewField
              label="Менеджер"
              loading={false}
              text={selectedEmployee?.manager?.display_name}
            />
            <CustomViewField
              label="Должность"
              loading={false}
              text={selectedEmployee?.occupation}
            />
          </Grid>
          <Grid items ld={4} md={12} sm={12} xl={4} xs={12}>
            <Typography
              sx={{
                mb: 2,
              }}
              variant="subtitle1"
            >
              Общие сведения
            </Typography>
            <CustomViewField
              label="Дата рождения"
              loading={false}
              text={dayjs(selectedEmployee?.birth_date).format("DD.MM.YYYY")}
            />
            <CustomViewField
              copyButton={true}
              label="Email"
              loading={false}
              text={selectedEmployee?.email}
            />
            <CustomViewField
              copyButton={true}
              label="Телефон"
              loading={false}
              text={selectedEmployee?.phone}
            />
            <CustomViewField
              copyButton={true}
              label="Доп. каналы связи"
              loading={false}
              text={selectedEmployee?.communication_channels}
            />
            <CustomViewField
              label="Часовой пояс"
              loading={false}
              text={selectedEmployee?.time_zone}
            />
            <CustomViewField
              label="Рабочий график"
              loading={false}
              text={selectedEmployee?.work_schedule}
            />
            <CustomViewField
              label="Страна"
              loading={false}
              text={selectedEmployee?.country}
            />
          </Grid>
          <Grid item sx={{ mt: 3 }} xs={12}>
            {roles?.some((role) => role === "ERP_Admins") && (
              <Button onClick={handleEdit} sx={{ mr: 1 }} variant="contained">
                Редактировать
              </Button>
            )}
            {!isMobile && (
              <Button color="primary" onClick={handleBack} variant="text">
                Назад к списку
              </Button>
            )}
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default EmployeePage;
