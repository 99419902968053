import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authReducer } from "./slice/auth.slice";
import { budgetsReducer } from "./slice/budgets.slice";
import { calendarReducer } from "./slice/calendar.slice"; // defaults to localStorage for web
import { clientsReducer } from "./slice/clients.slice";
import { contactsReducer } from "./slice/contacts.slice";
import { contractsReducer } from "./slice/contracts.slice";
import { departmentsReducer } from "./slice/departments.slice";
import { employeesReducer } from "./slice/employees.slice";
import { planningReducer } from "./slice/planning.slice";
import { projectsReducer } from "./slice/projects.slice";
import { scheduleReducer } from "./slice/schedule.slice";
import { serviceReducer } from "./slice/service.slice";
import { statisticsReducer } from "./slice/statistics.slice";
import { tableStatesReducer } from "./slice/tableStates.slice";
import { timesheetsReducer } from "./slice/timesheets.slice";
import { usersReducer } from "./slice/users.slice";

const rootReducer = combineReducers({
  authReducer,
  clientsReducer,
  contactsReducer,
  projectsReducer,
  departmentsReducer,
  employeesReducer,
  budgetsReducer,
  scheduleReducer,
  planningReducer,
  calendarReducer,
  serviceReducer,
  timesheetsReducer,
  tableStatesReducer,
  contractsReducer,
  usersReducer,
  statisticsReducer,
  // [sseApi.reducerPath]: sseApi.reducer,
});

const persistConfig = {
  key: "root",
  storage, // ~ storage: storage
  // blacklist: [''],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
