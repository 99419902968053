import React, { useCallback, useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useDropzone } from "react-dropzone";

import MaterialTable from "../components/MaterialTable";
import { TableCellsWrap } from "../components/TableCellsWrap";

const DownloadReports = () => {
  const [isOpenHistory, setIsOpenHistory] = useState(false);
  const tableColumns = [
    {
      accessorKey: "id",
      header: "ID",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "date",
      header: "Дата",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "name",
      header: "Имя файла",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "user",
      header: "Пользователь",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "state",
      header: "Состояние загрузки",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 72,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => (
        <Tooltip title="Скачать">
          <IconButton color="inherit" onClick={() => {}} size="large">
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      date: "12.06.2023",
      name: "Файл 1",
      user: "Имя",
      state: "Успех",
    },
    {
      id: 2,
      date: "27.07.2023",
      name: "Файл 2",
      user: "Имя",
      state: "Ошибка",
    },
  ];

  return (
    <Container>
      <Typography gutterBottom sx={{ mb: 2 }} variant="h4">
        Загрузка отчетов
      </Typography>
      <Grid item>
        <Card sx={{ p: 2 }}>
          <TestDropZone />
        </Card>
      </Grid>
      <Grid item sx={{ mt: 2 }}>
        <Card sx={{ p: 2 }}>
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              expandIcon={<ExpandMoreIcon />}
              id="panel1a-header"
            >
              <Typography>История загрузок</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item>
                <MaterialTable columns={tableColumns} data={data} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Card>
      </Grid>
    </Container>
  );
};

export default DownloadReports;

const TestDropZone = () => {
  const onDrop = useCallback((e) => console.log("onDrop", e), []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = acceptedFiles.map((file) => (
    <Typography key={file.name} sx={{ pt: 2 }}>
      {file.name} - {file.size} bytes
    </Typography>
  ));

  return (
    <>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ height: "200px", cursor: "pointer" }}
      >
        <input {...getInputProps()} />
        <p>
          Перетащите сюда файл или <u>нажмите</u>, чтобы выбрать
        </p>
      </div>
      {acceptedFiles.length > 0 && (
        <>
          {files}
          <Button onClick={() => {}} sx={{ mt: 2 }} variant="contained">
            Загрузить
          </Button>
        </>
      )}
    </>
  );
};
