import React, { useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Card, IconButton, Tooltip } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import BudgetsAdd from "./BudgetsAdd";
import BudgetsEdit from "./BudgetsEdit";
import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { RowOptionsStack } from "../../theme/standarts_styles";
import BlockFormModal from "../BlockFormModal";
import Iconify from "../iconify";
import MaterialTable from "../MaterialTable";
import { TableCellsWrap } from "../TableCellsWrap";

const Budgets = () => {
  const { t, i18n } = useTranslation();

  const { setBudgets } = useActions();

  const { budgets } = useSelector((state) => state.budgetsReducer);
  const isMobile = useResponsive("down", "md");

  const { selectedProject } = useSelector((state) => state.projectsReducer);

  const [budgetId, setBudgetId] = useState(null);
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const tableColumns = [
    {
      id: "date",
      header: "Дата",
      accessorFn: (row) => dayjs(row.date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "name",
      header: "Имя бюджета",
      accessorFn: (row) => row.name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "type",
      header: "Вид",
      accessorFn: (row) => row.type || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "justification",
      header: "Обоснование",
      accessorFn: (row) => row.justification || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "currency",
      header: "Валюта",
      accessorFn: (row) => row.currency || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "currency_amount",
      header: "Сумма бюджета в валюте",
      accessorFn: (row) => row.currency_amount || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "hours_amount",
      header: "Сумма бюджета в часах",
      accessorFn: (row) => row.hours_amount || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title="Подтвердить">
                <IconButton
                  color="inherit"
                  onClick={() => handleDeleteRow(row.original.id)}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Отмена">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(null)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <RowOptionsStack>
            <Tooltip title="Редактировать">
              <IconButton
                color="inherit"
                onClick={() => handleEditRow(row.original.id)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                color="inherit"
                onClick={() => setConfirmationId(row.original.id)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </RowOptionsStack>
        );
      },
    },
  ];

  const handleAdd = () => {
    setOpenEditForm(false);
    setOpenAddForm(true);
  };

  function handleEditRow(id) {
    setBudgetId(id);
    setOpenAddForm(false);
    setOpenEditForm(true);
  }

  async function handleDeleteRow(id_row) {
    setLoading(true);
    try {
      await $authHost.delete(`/budgets/${id_row}/delete/`).then((response) => {
        loadData();
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  function handleClose() {
    setOpenAddForm(false);
    setOpenEditForm(false);
  }

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/budgets/all/?project_id=${selectedProject.id}`,
      );
      setBudgets(response.data.items);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [selectedProject.id, setBudgets]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="start"
        mb={0}
        sx={{ height: "70px" }}
      >
        <Button
          aria-haspopup="true"
          onClick={handleAdd}
          size={isMobile ? "small" : "medium"}
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ mr: 0 }}
          variant="contained"
        >
          Добавить бюджет
        </Button>
      </Stack>
      {/*<AlertMessage/>*/}
      {openAddForm && (
        <BudgetsAdd handleClose={handleClose} loadDataBudgets={loadData} />
      )}
      {openEditForm && (
        <BudgetsEdit
          budgetId={budgetId}
          handleClose={handleClose}
          loadDataBudgets={loadData}
        />
      )}
      <Card sx={{ pt: 1 }}>
        <MaterialTable
          columns={tableColumns}
          data={budgets}
          loading={loading}
        />
      </Card>
      <BlockFormModal
        title="Вы не завершили действие внутри таблицы"
        when={!!confirmationId}
      />
    </>
  );
};

export default Budgets;
