import { useEffect } from "react";

import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import Header from "./header/Header";
import Nav from "./nav/Nav";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { Main, StyledRoot } from "../theme/standarts_styles";

export default function DashboardLayout() {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  const { isInitAuth } = useSelector((state) => state.authReducer);
  const { openNav } = useSelector((state) => state.serviceReducer);
  const { setOpenNav, toggleOpenNav } = useActions();

  useEffect(() => {
    //закрываем боковое меню при навигации с мобильного устройства
    if (openNav && !isDesktop) {
      toggleOpenNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    //закрываем боковое меню при входе в режим мобильного устройства
    if (!isDesktop) {
      setOpenNav(false);
    }
  }, [isDesktop, setOpenNav]);

  return (
    <StyledRoot>
      <Header />
      {isInitAuth === true && <Nav />}
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
