import { useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Card, IconButton, Tooltip, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BlockFormModal from "../components/BlockFormModal";
import Iconify from "../components/iconify";
import MaterialTablePagination from "../components/MaterialTablePagination";
import { TableCellsWrap } from "../components/TableCellsWrap";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";
import { $authHost } from "../http";
import { RowOptionsStack } from "../theme/standarts_styles";
import { getSortingString } from "../utils/getSortingString";

const Projects = () => {
  const tableId = "projects";
  const tableStates = useSelector((state) => state.tableStatesReducer);
  const isMobile = useResponsive("down", "md");

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { setProjects, setSelectedProject } = useActions();
  const { projects } = useSelector((state) => state.projectsReducer);
  const { roles } = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const [searchText, setSearchText] = useState(
    tableStates[tableId]?.globalFilter || "",
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: tableStates[tableId]?.pagination?.pageSize || 20,
  });
  const [sorting, setSorting] = useState(tableStates[tableId]?.sorting || []);
  const [rowCount, setRowCount] = useState(0);
  const [pageCount, setPageCount] = useState(-1);

  const tableColumns = [
    {
      id: "code",
      header: "Код проекта",
      accessorFn: (row) => row.code || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "name",
      header: "Название проекта",
      accessorFn: (row) => row.name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "client_name",
      header: "Клиент",
      accessorFn: (row) => row.client?.name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "client_code",
      header: "Код клиента",
      accessorFn: (row) => row.client?.code || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "notes",
      header: "Описание проекта",
      accessorFn: (row) => row.notes || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "status",
      header: "Статус",
      accessorFn: (row) => row.status || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "manager_display_name",
      header: "Менеджер проекта",
      accessorFn: (row) => row.manager?.display_name || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "team",
      header: "Команда проекта",
      accessorFn: (row) => {
        if (Array.isArray(row?.team)) {
          const team = row.team.map((t) => t.display_name);
          return team.join(", ");
        }
        return "";
      },
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "start_date",
      header: "Начало (план)",
      accessorFn: (row) =>
        row.start_date ? dayjs(row.start_date).format("DD.MM.YYYY") : "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "end_date",
      header: "Конец (план)",
      accessorFn: (row) =>
        row.end_date ? dayjs(row.end_date).format("DD.MM.YYYY") : "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      id: "internal_hours_budget",
      header: "Бюджет (внутр. часы)",
      accessorFn: (row) => row.internal_hours_budget || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 168,
      enableColumnFilter: false,
      enableSorting: false,
      enableHiding: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title="Подтвердить">
                <IconButton
                  color="inherit"
                  onClick={() => handleDeleteRow(row.original.id)}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Отмена">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(null)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <RowOptionsStack>
            <Tooltip title="Редактировать">
              <IconButton
                color="inherit"
                onClick={() => handleEditRow(row.original.id)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {roles?.some((role) => role === "ERP_Admins") && (
              <Tooltip title="Удалить">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(row.original.id)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </RowOptionsStack>
        );
      },
    },
  ];

  const handleEditRow = (data) => {
    navigate(`/projects/edit/${data}`);
    setSelectedProject(data);
  };

  const handleOpenViewPage = (row) => {
    const id = row.original.id;
    if (id) {
      navigate(`/projects/${id}`);
      setSelectedProject(id);
    }
  };

  function handleGoToAddPage(selectedChannel = null) {
    navigate("/projects/new");
  }

  async function handleDeleteRow(id) {
    setLoading(true);
    try {
      await $authHost.delete(`/projects/${id}/delete/`).then((response) => {
        loadData();
      });
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmationId(null);
      setLoading(false);
    }
  }

  const loadData = useCallback(
    async (search, index, size, sorting) => {
      setLoading(true);

      try {
        const sortStr = getSortingString(sorting);

        const response = await $authHost.get(`/projects/all/`, {
          params: {
            page: index + 1,
            size: size,
            ...(search && { search: search }),
            ...(sortStr && { sort_by: sortStr }),
          },
        });

        const data = response.data.items;
        setProjects(data);
        setRowCount(response.data?.total);
        setPageCount(response.data?.pages);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [setProjects],
  );

  useEffect(() => {
    loadData(searchText, pagination.pageIndex, pagination.pageSize, sorting);
  }, [
    loadData,
    pagination.pageIndex,
    pagination.pageSize,
    searchText,
    sorting,
  ]);

  const onGlobalFilterChange = (v) => {
    setSearchText(v);
  };

  return (
    <Container sx={{ pb: 0 }}>
      <Stack alignItems="center" direction="row">
        <Typography gutterBottom sx={{ mb: 0, mr: 4 }} variant="h4">
          Проекты
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="start"
        mb={0}
        sx={{ height: "70px" }}
      >
        <Button
          onClick={handleGoToAddPage}
          size={isMobile ? "small" : "medium"}
          startIcon={<Iconify icon="eva:plus-fill" />}
          variant="contained"
        >
          Добавить проект
        </Button>
      </Stack>
      <Card sx={{ pt: 1 }}>
        <MaterialTablePagination
          columns={tableColumns}
          data={projects}
          enableHiding={true}
          id={tableId}
          initialState={{
            columnVisibility: { client_code: false, notes: false, team: false },
          }}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleOpenViewPage(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
          onGlobalFilterChange={onGlobalFilterChange}
          onSortingChange={setSorting}
          pageCount={pageCount}
          pagination={pagination}
          rowCount={rowCount}
          search={searchText}
          setPagination={setPagination}
          sorting={sorting}
        />
      </Card>
      <BlockFormModal
        title="Вы не завершили действие внутри таблицы"
        when={!!confirmationId}
      />
    </Container>
  );
};

export default Projects;
