import React from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";

const CustomDatepicker = ({ name, label, rules, ...rest }) => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const error = errors[name];
  const req = !!rules?.required;

  return (
    <div style={{ position: "relative", marginBottom: "12px", width: "100%" }}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              control={control}
              label={`${label}${req ? " *" : ""}`}
              onChange={(newValue) => {
                setValue(name, newValue, { shouldDirty: true });
                // field.onChange(newValue);
              }}
              value={getValues(name) ? dayjs(getValues(name)) : null}
              {...rest}
              slotProps={{
                textField: {
                  InputLabelProps: { shrink: true },
                  size: rest.size || "medium",
                  error: !!error,
                },
              }}
            />
          </LocalizationProvider>
        )}
        rules={rules}
      />
      <span
        style={{
          position: "absolute",
          bottom: "4px",
          fontSize: "12px",
          color: "red",
          left: 14,
        }}
      >
        {error && (error.message || "Unknown error!")}
      </span>
    </div>
  );
};

export default CustomDatepicker;
