import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BlockFormModal from "../../components/BlockFormModal";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import CustomTextField from "../../components/TextFields/CustomTextField";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";

const ClientsAddForm = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useResponsive("down", "md");

  const methods = useForm({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful },
    control,
    setError,
    clearErrors,
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let new_arr = {
        address: data.address || null,
        code: data.code || null,
        country: data.country || null,
        details: data.details || null,
        email: data.email || null,
        full_name: data.full_name || null,
        name: data.name || null,
        web_site: data.web_site || null,
      };
      new_arr["contacts"] = [];
      return await $authHost.post(`/clients/add/`, new_arr);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/clients");
  };

  const checkUniqueCode = async (value) => {
    const { data } = await $authHost.get(
      `/clients/unique_check/?code=${value}`,
    );
    if (data?.unique_code === false) {
      setError("code", {
        type: "custom",
        message: "Код должен быть уникальным",
      });
    }
    if (data?.unique_code === true) {
      clearErrors("code");
    }
  };

  const validateName = async (value) => {
    if (value) {
      const trimmedName = value.toString().trim();
      if (trimmedName) {
        const { data } = await $authHost.get(
          `/clients/unique_check/?name=${trimmedName}`,
        );
        if (data?.unique_name === false) {
          return "Наименование должно быть уникальным";
        }
        if (data?.unique_name === true) {
          return true;
        }
      }
    }
  };

  const validateCode = async (value) => {
    if (value) {
      const trimmedCode = value.toString().trim();
      if (trimmedCode) {
        const { data } = await $authHost.get(
          `/clients/unique_check/?code=${trimmedCode}`,
        );
        if (data?.unique_code === false) {
          return "Код должен быть уникальным";
        }
        if (data?.unique_code === true) {
          return true;
        }
      }
    }
  };

  return (
    <Container>
      {isMobile ? (
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="space-between"
          sx={{ mb: 1, mt: 1 }}
        >
          <Grid item>
            <Typography color="text.primary" variant="h4">
              Добавить клиента
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleBack} sx={{ p: 0 }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <TitleStack>
          <Typography variant="h4">Сведения о клиенте</Typography>
        </TitleStack>
      )}
      <Box sx={{ flexDirection: "column", px: 0, py: isMobile ? 2 : 4 }}>
        {loading && <LoadingSpinner />}
        <FormProvider {...methods}>
          <form>
            <CustomTextField
              label="Наименование"
              name="name"
              registerOptions={{
                required: t("main.another.requiredField"),
                validate: (value) => validateName(value),
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Полное наименование"
              name="full_name"
              registerOptions={{
                required: t("main.another.requiredField"),
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Код клиента"
              name="code"
              registerOptions={{
                required: t("main.another.requiredField"),
                validate: (value) => validateCode(value),
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Адрес"
              multiline={true}
              name="address"
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              rows={3}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Веб-сайт"
              name="web_site"
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Email"
              name="email"
              registerOptions={{
                // required: t('main.another.requiredField'),
                pattern: {
                  value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: t("main.another.wrongInput"),
                },
              }}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Реквизиты"
              multiline={true}
              name="details"
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              rows={3}
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            <CustomTextField
              label="Страна"
              name="country"
              registerOptions={
                {
                  // required: t('main.another.requiredField'),
                }
              }
              size="small"
              sx={{ width: "100%", fontSize: "12px", mb: 3 }}
            />
            {isMobile ? (
              <Stack
                alignItems="center"
                direction="column"
                justifyContent="center"
                spacing={2}
                sx={{ width: "100%", mt: 3 }}
              >
                <Button
                  color="secondary"
                  onClick={handleSubmit((data) => {
                    onSubmit(data).then((res) => {
                      if (res) {
                        navigate("/clients");
                      }
                    });
                  })}
                  sx={{ width: "100%" }}
                  variant="contained"
                >
                  Сохранить
                </Button>
                <Button
                  onClick={handleSubmit((data) => {
                    onSubmit(data).then((res) => {
                      if (res?.data?.id) {
                        navigate(`/clients/edit/${res.data.id}/contacts`);
                      } else if (res) {
                        navigate("/clients");
                      }
                    });
                  })}
                  sx={{ width: "100%" }}
                  variant="contained"
                >
                  Сохранить и добавить контакты
                </Button>
                <Button
                  onClick={() => reset()}
                  sx={{ width: "100%" }}
                  variant="outlined"
                >
                  {t("src.components.Intelligence.reset")}
                </Button>
              </Stack>
            ) : (
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={1}
              >
                <Grid item>
                  <Button
                    onClick={handleSubmit((data) => {
                      onSubmit(data).then((res) => {
                        if (res) {
                          navigate("/clients");
                        }
                      });
                    })}
                    variant="contained"
                  >
                    Сохранить
                  </Button>
                  <Button
                    onClick={handleSubmit((data) => {
                      onSubmit(data).then((res) => {
                        if (res?.data?.id) {
                          navigate(`/clients/edit/${res.data.id}/contacts`);
                        } else if (res) {
                          navigate("/clients");
                        }
                      });
                    })}
                    sx={{ ml: 1 }}
                    variant="contained"
                  >
                    Сохранить и создать контакты
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleBack}
                    sx={{ ml: 1 }}
                    variant="text"
                  >
                    Назад
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    onClick={() => reset()}
                    sx={{ ml: 1 }}
                    variant="text"
                  >
                    Сбросить
                  </Button>
                </Grid>
              </Stack>
            )}
          </form>
        </FormProvider>
        <BlockFormModal when={isDirty && !isSubmitSuccessful} />
      </Box>
    </Container>
  );
};

export default ClientsAddForm;
