import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employees: [],
  selectedEmployee: null,
  orgAdmin: false,
};

const EmployeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setOrgAdmin(state, { payload }) {
      state.orgAdmin = payload;
    },
    setEmployees(state, { payload }) {
      state.employees = payload;
    },
    setSelectedEmployee(state, { payload }) {
      const index = state.employees.findIndex(
        (employee) => employee.source_id === payload,
      );
      state.selectedEmployee = state.employees[index];
    },
    setDataSelectedEmployee(state, { payload }) {
      state.selectedEmployee = payload;
    },
  },
});

export const { actions: employeesActions, reducer: employeesReducer } =
  EmployeesSlice;
export default EmployeesSlice.reducer;
