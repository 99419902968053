import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useActions } from "../../hook/useActions";
import useResponsive from "../../hook/useResponsive";
import { $authHost } from "../../http";
import CustomViewField from "../CustomViewField";

const CommonSettings = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const isMobile = useResponsive("down", "md");

  const { setProject } = useActions();
  const { project } = useSelector((state) => state.projectsReducer);

  const [loading, setLoading] = useState(false);

  const loadProject = useCallback(() => {
    setLoading(true);
    try {
      const getProject = async () => {
        const response = await $authHost.get(`/projects/${projectId}/`);
        setProject(response.data);
        setLoading(false);
      };
      getProject();
    } catch (e) {
      console.log(e);
    }
  }, [projectId, setProject]);

  useEffect(() => {
    loadProject();
  }, [loadProject]);

  const handleBack = () => {
    navigate("/projects");
  };

  const handleEdit = () => {
    navigate(`/projects/edit/${projectId}`);
  };

  return (
    <Box sx={{ flexDirection: "column", px: 0, py: 2 }}>
      <Grid columnSpacing={4} container rowSpacing={2}>
        <Grid item ld={6} md={12} sm={12} xl={6} xs={12}>
          <Typography
            sx={{
              mb: 2,
            }}
            variant="subtitle1"
          >
            Общие сведения
          </Typography>
          <CustomViewField
            label="Проект"
            loading={loading}
            text={`${project.code} ${project.name}`}
          />
          <CustomViewField
            label="Клиент"
            loading={loading}
            path={`/clients/${project.client?.id}`}
            text={`${project.client?.code} ${project.client?.name}`}
            type="link"
          />
          <CustomViewField
            label="Статус проекта"
            loading={loading}
            text={project.status}
          />
          <CustomViewField
            label="Описание"
            loading={loading}
            text={project.notes}
          />
        </Grid>
        <Grid item ld={6} md={12} sm={12} xl={6} xs={12}>
          <Typography
            sx={{
              mb: 2,
            }}
            variant="subtitle1"
          >
            Интеграция
          </Typography>
          <CustomViewField
            label="ClickUp"
            loading={loading}
            text={project.clickup}
          />
          <CustomViewField
            label="Teams"
            loading={loading}
            text={project.teams}
          />
          <CustomViewField
            label="Azure"
            loading={loading}
            text={project.azure_dev_ops_space}
          />
        </Grid>
        <Grid item ld={6} md={12} sm={12} xl={6} xs={12}>
          <Typography
            sx={{
              mb: 2,
            }}
            variant="subtitle1"
          >
            Свойства
          </Typography>
          <Grid columnSpacing={2} container>
            <Grid item xs={6}>
              <CustomViewField
                label="Старт проекта"
                loading={loading}
                text={
                  project.actual_start_date
                    ? dayjs(project.actual_start_date).format("DD.MM.YYYY")
                    : ""
                }
              />
              <CustomViewField
                label="Дата завершения"
                loading={loading}
                text={
                  project.actual_end_date
                    ? dayjs(project.actual_end_date).format("DD.MM.YYYY")
                    : ""
                }
              />
              <CustomViewField
                label="Валюта проекта"
                loading={loading}
                text={project.currency}
              />
              <CustomViewField
                label="Бюджет в валюте"
                loading={loading}
                text={project.currency_budget}
              />
              <CustomViewField
                label="Бюджет в ком. часах"
                loading={loading}
                text={project.commercial_hours_budget}
              />
              <CustomViewField
                label="Бюджет во вн. часах"
                loading={loading}
                text={project.internal_hours_budget}
              />
            </Grid>
            <Grid item ld={6} md={12} sm={12} xl={6} xs={12}>
              <CustomViewField
                label="Старт План"
                loading={loading}
                text={
                  project.start_date
                    ? dayjs(project.start_date).format("DD.MM.YYYY")
                    : ""
                }
              />
              <CustomViewField
                label="Завершение План"
                loading={loading}
                sx={{ mb: isMobile ? 1 : 10 }}
                text={
                  project.end_date
                    ? dayjs(project.end_date).format("DD.MM.YYYY")
                    : ""
                }
              />
              <CustomViewField
                label="Нач. бюджет в валюте"
                loading={loading}
                text={project.currency_budget_start}
              />
              <CustomViewField
                label="Нач. бюджет в ком. часах"
                loading={loading}
                text={project.commercial_hours_budget_start}
              />
              <CustomViewField
                label="Нач. бюджет во вн. часах"
                loading={loading}
                text={project.internal_hours_budget_start}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item ld={6} md={12} sm={12} xl={6} xs={12}>
          <Typography
            sx={{
              mb: 2,
            }}
            variant="subtitle1"
          >
            Команда проекта
          </Typography>
          <CustomViewField
            label="Менеджер проекта"
            loading={loading}
            text={project.manager?.display_name}
          />
          <CustomViewField
            label="Команда"
            loading={loading}
            text={
              Array.isArray(project.team)
                ? project.team.map((t) => t.display_name).join(", ")
                : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleBack} variant="contained">
            Назад к списку
          </Button>
          <Button
            color="primary"
            onClick={handleEdit}
            sx={{ ml: 1 }}
            variant="text"
          >
            Редактировать
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommonSettings;
