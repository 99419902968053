import React, { createContext, useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import TimeoutWarning from "../components/modals/TimeoutWarning";
import { useActions } from "../hook/useActions";
import { $authHost } from "../http";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { logOut, setWarningModalOpen, setChats } = useActions();

  const {
    isWarningModalOpen,
    token,
    tokenLifetime,
    idTokenLifetime,
    startTime,
  } = useSelector((state) => state.authReducer);

  const signOut = useCallback(() => {
    logOut();
    // localStorage.clear() // Чистим истирию redux-persist пока не удалять
    navigate("/login");
  }, [logOut, navigate]);

  const sessionLifeTime =
    idTokenLifetime < tokenLifetime ? idTokenLifetime : tokenLifetime; //время через которое сессия должна быть окончена
  const warningTime = 600000; //за какое время перед окончанием сессии нужно предупредить пользователя

  useEffect(() => {
    // Запуск таймеров проверки жизни сессии
    if (startTime !== null) {
      const now = new Date().getTime();
      const remainingSessionLifeTime = sessionLifeTime - (now - startTime);
      const remainingWarningTime =
        sessionLifeTime - warningTime - (now - startTime);
      const timerSessionLifeTime = setTimeout(() => {
        signOut();
      }, remainingSessionLifeTime);
      const timerWarningTime = setTimeout(() => {
        if (isWarningModalOpen === false) {
          setWarningModalOpen(true);
        }
      }, remainingWarningTime);
      return () => {
        clearTimeout(timerSessionLifeTime);
        clearTimeout(timerWarningTime);
      };
    }
  }, [
    token,
    startTime,
    sessionLifeTime,
    signOut,
    isWarningModalOpen,
    setWarningModalOpen,
  ]);

  const value = {
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      {isWarningModalOpen && (
        <TimeoutWarning
          isOpen={isWarningModalOpen}
          onRequestClose={() => {
            setWarningModalOpen(false);
          }}
        />
      )}
    </AuthContext.Provider>
  );
};
