import React, { useState } from "react";

import {
  Box,
  Tabs,
  Tab,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Departments from "../components/OrgStructure/Departments";
import Employees from "../components/OrgStructure/Employees";
import MySchedule from "../components/Schedule/MySchedule";
import RemoteDynamicTabs from "../components/Tabs/RemoteDynamicTabs";
import { useActions } from "../hook/useActions";
import { TitleStack } from "../theme/standarts_styles";

const OrgStructure = () => {
  const { t, i18n } = useTranslation();
  // const [value, setValue] = React.useState(0);
  const location = useLocation();

  // function handleChange(event, newValue) {
  //   setValue(newValue);
  // }
  //

  const tabs = [
    { label: "Сотрудники", path: "employees" },
    { label: "Отделы", path: "departments" },
  ];

  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith("/")) {
      redirectPath += "/";
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }

  return (
    <Container sx={{ pb: 0 }}>
      <TitleStack>
        <Typography gutterBottom variant="h4">
          Организационная структура
        </Typography>
      </TitleStack>
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
};

export default OrgStructure;
